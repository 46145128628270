import { Listbox, ListboxButton } from '@headlessui/react';
import { DropdownContainer } from '@admin/features/theme/components/DropdownContainer/DropdownContainer';
import { Button } from '@admin/features/theme/components/Button/Button';
import { DropdownOption as TDropdownOption } from '@admin/features/theme/components/SelectLanguage/SelectLanguage';
import { useFormContext } from 'react-hook-form';
import { TruncateText } from '@admin/features/theme/components/TruncateText/TruncateText';
import {
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@admin/features/theme/components/Form/Form';
import { cn } from '@admin/utils/cn';
import { DropdownOption } from '../DropdownOption/DropdownOption';

type DropdownProps<T extends string> = {
  defaultSelectedOption?: TDropdownOption<T>;
  options?: TDropdownOption<T>[];
  name: string;
  label?: string;
  className?: string;
  onChange?(option: TDropdownOption): void;
};

const MISSING_VALUE = '--';

export const Dropdown = <T extends string = string>({
  options,
  name,
  label,
  onChange,
  className,
}: DropdownProps<T>): JSX.Element => {
  const { setValue, control } = useFormContext();

  const handleChange = (selectedOption: TDropdownOption<T>): void => {
    setValue(name, selectedOption.id);
    onChange?.(selectedOption);
  };

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => {
        const option = options?.find((o) => o.id === field.value);
        return (
          <FormItem>
            <div className={cn('relative w-max flex flex-col', className)}>
              {label && <FormLabel>{label}</FormLabel>}
              <Listbox value={option} onChange={handleChange}>
                <ListboxButton as={Button} variant='outline'>
                  {option?.name ?? MISSING_VALUE}
                </ListboxButton>
                <div>
                  <DropdownContainer>
                    {options?.map((option) => (
                      <DropdownOption key={option.id} value={option}>
                        <TruncateText>{option.name}</TruncateText>
                      </DropdownOption>
                    ))}
                  </DropdownContainer>
                </div>
              </Listbox>
              <FormMessage />
            </div>
          </FormItem>
        );
      }}
    />
  );
};
