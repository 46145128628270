import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { CreateNotificationPayload } from '@admin/features/layout/types/Notification';
import { layoutActions } from '@admin/features/layout/state/layoutSlice';

export const useNotificationDispatch = () => {
  const dispatch = useDispatch();
  return useCallback(
    (notification: CreateNotificationPayload) =>
      dispatch(layoutActions.createNotification(notification)),
    [dispatch]
  );
};
