import { TagResponseDto } from '@admin/features/api/generated';
import { Modal } from '@admin/features/theme/components/Modals/Modal';
import React, { useEffect } from 'react';
import useLazyQuery from '@admin/features/api/useLazyQuery';
import { api } from '@admin/features/api';
import { useModal } from '@admin/features/layout/modals/useModal';
import { useBlogsTranslation } from '@admin/features/blogs/locales/useBlogsTranslation';
import { useDispatch } from '@admin/store/store';
import { editorActions } from '../../state/editorSlice';

export type RemoveTagModalProps = {
  tag: TagResponseDto;
};

export const RemoveTagModal: React.FC<RemoveTagModalProps> = ({ tag }) => {
  const { t } = useBlogsTranslation();
  const { openPreviousModal, closeModal } = useModal();
  const dispatch = useDispatch();

  const [fetch, { isSuccess, isError }] = useLazyQuery(
    ['tagControllerRemove'],
    () => api.tagControllerRemove(tag.id)
  );

  const confirmHandler = (): void => {
    fetch();
  };

  useEffect(() => {
    if (isError) {
      // eslint-disable-next-line no-warning-comments
      // TODO handle
    }

    if (isSuccess) {
      dispatch(editorActions.removeTag(tag));
      openPreviousModal();
    }
  }, [dispatch, isError, isSuccess, openPreviousModal, tag]);

  return (
    <Modal
      isOpen
      title={t('editor.modals.remove-tag.title')}
      onClose={closeModal}
      onConfirm={confirmHandler}
      onPrevious={openPreviousModal}
      closeOnOutsideClick
    >
      {t('editor.modals.remove-tag.message', { name: tag.slug })}
    </Modal>
  );
};
