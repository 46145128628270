import { FC } from 'react';
import { ScaleLoader } from 'react-spinners';
import { LoaderHeightWidthRadiusProps } from 'react-spinners/helpers/props';

export type LoaderProps = LoaderHeightWidthRadiusProps;

export const Loader: FC<LoaderProps> = (props) => {
  return (
    <span className='flex justify-center items-center'>
      <ScaleLoader height={24} color='hsl(var(--foreground))' {...props} />
    </span>
  );
};
