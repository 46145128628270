import auth from '@admin/features/auth/locales';
import users from '@admin/features/users/locales';
import common from '@admin/locales/common';
import layout from '@admin/features/layout/locales';
import blogs from '@admin/features/blogs/locales';
import assets from '@admin/features/assets/locales';
import dashboard from '@admin/features/dashboard/locales';

export const i18nResources = {
  en: {
    users: users.en,
    auth: auth.en,
    common: common.en,
    layout: layout.en,
    blogs: blogs.en,
    assets: assets.en,
    dashboard: dashboard.en,
  },
};
