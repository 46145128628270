import { Modal } from '@admin/features/theme/components/Modals/Modal';
import React, { useState } from 'react';

import * as yup from 'yup';
import { useForm } from '@admin/hooks/useForm';
import {
  SelectLanguage,
  DropdownOption,
  DEFAULT_LANGUAGE_OPTION,
} from '@admin/features/theme/components/SelectLanguage/SelectLanguage';
import {
  Language,
  TagResponseDto,
  TagVisibility,
} from '@admin/features/api/generated';
import { Controller, FormProvider } from 'react-hook-form';
import { InputField } from '@admin/features/form/components/InputField';
import { useMutation, useQuery } from '@tanstack/react-query';
import { api } from '@admin/features/api';
import { Dropdown } from '@admin/features/theme/components/Dropdown/Dropdown';
import { ColorPicker } from '@admin/features/theme/components/ColorPicker/ColorPicker';
import { RegexUtils } from '@admin/utils/RegexUtils';
import { convertStringToSlug } from '@admin/utils/convertStringToSlug';
import { useNotificationDispatch } from '@admin/features/layout/hooks/useNotificationDispatch';
import { useModal } from '@admin/features/layout/modals/useModal';
import { useBlogsTranslation } from '@admin/features/blogs/locales/useBlogsTranslation';
import { useDispatch } from '@admin/store/store';
import { editorActions } from '../../state/editorSlice';
import { AxiosError } from 'axios';

// eslint-disable-next-line no-warning-comments
// TODO implement Tag preview

const schema = yup.object().shape({
  color: yup.string().required(),
  visibility: yup
    .mixed<TagVisibility>()
    .oneOf(Object.values(TagVisibility))
    .required(),
  slug: yup
    .string()
    .matches(RegexUtils.slugRegex, 'Must be a valid slug')
    .required()
    .ensure(),
  tagGroupId: yup.string().uuid('Must be a valid UUID').required(),
  translations: yup
    .array()
    .of(
      yup.object().shape({
        language: yup
          .mixed<Language>()
          .oneOf(Object.values(Language))
          .required(),
        name: yup.string().required(),
      })
    )
    .min(1)
    .required(),
});

type FormValues = yup.InferType<typeof schema>;

const getInitialTranslations = (
  language: Language
): FormValues['translations'][number] => ({
  language,
  name: '',
});

const getLanguageOptions = (
  translations?: TagResponseDto['translations']
): DropdownOption<Language, Language>[] | undefined => {
  return translations?.map(({ language }) => ({
    id: language,
    name: language,
  }));
};

export type CreateTagModalProps = {
  tag?: TagResponseDto;
};

export const CreateTagModal: React.FC<CreateTagModalProps> = ({ tag }) => {
  const enableTagUpdate = Boolean(tag);
  const { t } = useBlogsTranslation();
  const { closeModal, openPreviousModal } = useModal();
  const createNotification = useNotificationDispatch();
  const dispatch = useDispatch();

  const defaultLanguageOptions = getLanguageOptions(tag?.translations);

  const [languageOptions, setLanguageOptions] = useState<
    DropdownOption<Language, Language>[]
  >(defaultLanguageOptions ?? [DEFAULT_LANGUAGE_OPTION]);
  const [selectedLanguageId, setSelectedLanguageId] = useState<Language>(
    defaultLanguageOptions?.[0].id ?? DEFAULT_LANGUAGE_OPTION.id
  );
  const [isSlugChanged, setIsSlugChanged] = useState<boolean>(enableTagUpdate);

  const methods = useForm({
    schema,
    defaultValues: schema.cast({
      color: tag?.color ?? '',
      visibility: tag?.visibility ?? TagVisibility.Internal,
      slug: tag?.slug ?? '',
      tagGroupId: tag?.tagGroup?.id ?? '',
      translations: tag?.translations ?? [
        getInitialTranslations(DEFAULT_LANGUAGE_OPTION.name),
      ],
    }),
  });

  const { data: blogGroupData } = useQuery({
    queryFn: () => api.tagGroupControllerFindAll(),
    queryKey: ['tagGroupControllerFindAll'],
    staleTime: 0,
  });

  const { mutate, isPending } = useMutation({
    mutationFn: (values: FormValues) => {
      if (enableTagUpdate) {
        return api.tagControllerUpdate(tag!.id, values);
      }
      return api.tagControllerCreate(values);
    },
    onSuccess: (response) => {
      createNotification({
        variant: 'success',
        content: enableTagUpdate
          ? t('editor.modals.select-tags.update.success')
          : t('editor.modals.select-tags.create.success'),
      });
      dispatch(editorActions.appendTag(response.data));
      openPreviousModal();
    },
    onError: (error: AxiosError) => {
      createNotification({
        variant: 'error',
        content:
          error.status === 409
            ? t('editor.modals.select-tags.errors.conflict')
            : error.message,
      });
    },
  });

  const selectLanguageInDropdownHandler = (
    languageOptions: DropdownOption<Language, Language>[]
  ): void => {
    setLanguageOptions(languageOptions);

    if (
      languageOptions.length ||
      languageOptions.find(({ id }) => id !== selectedLanguageId)
    ) {
      setSelectedLanguageId(languageOptions[0]?.id);
    }
  };

  const onSubmit = (data: FormValues): void => {
    mutate(data);
  };

  const selectLanguageHandler = (languageId: Language): void => {
    setSelectedLanguageId(languageId);
  };

  const createSlugHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (!isSlugChanged) {
      const slug = convertStringToSlug(e.target.value);
      methods.setValue(`slug`, slug);
    }
  };

  const changeSlugHandler = (): void => {
    if (!isSlugChanged) {
      setIsSlugChanged(true);
    }
  };

  const languageIndex = languageOptions.findIndex(
    (option) => option.id === selectedLanguageId
  );

  return (
    <Modal
      isOpen
      title={t('editor.modals.create-tag.title')}
      onClose={closeModal}
      onPrevious={openPreviousModal}
      onConfirm={methods.handleSubmit(onSubmit)}
      closeOnOutsideClick
      loading={isPending}
    >
      <FormProvider {...methods}>
        <SelectLanguage
          title={t('editor.modals.create-tag.buttons.select-language')}
          onChange={selectLanguageInDropdownHandler}
          getTranslations={getInitialTranslations}
          selectedLanguageId={selectedLanguageId}
          onLanguageChange={selectLanguageHandler}
        />
        <Controller
          name='translations'
          control={methods.control}
          render={({ field }) => (
            <>
              {field.value
                .filter(
                  (translation) => translation.language === selectedLanguageId
                )
                .map(() => {
                  return (
                    <React.Fragment key={languageIndex}>
                      <InputField
                        label={t('editor.modals.create-tag.form.name')}
                        name={`translations.${languageIndex.toString()}.name`}
                        onChange={createSlugHandler}
                      />
                    </React.Fragment>
                  );
                })}
            </>
          )}
        />
        <InputField
          label={t('editor.modals.create-tag.form.slug')}
          name={'slug'}
          onChange={changeSlugHandler}
        />
        <div className='flex items-center'>
          <ColorPicker
            label={t('editor.modals.create-tag.form.color')}
            name={'color'}
          />
          <div className='flex flex-col w-full gap-4'>
            <Dropdown
              label={t('editor.modals.create-tag.form.visibility')}
              name='visibility'
              options={Object.values(TagVisibility).map((name) => ({
                id: name,
                name,
              }))}
              className='w-full'
            />
            <Dropdown
              label={t('editor.modals.create-tag.form.tag-group')}
              name='tagGroupId'
              options={blogGroupData?.data ?? []}
              className='w-full'
            />
          </div>
        </div>
      </FormProvider>
    </Modal>
  );
};
