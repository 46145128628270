import {
  CreateNotificationPayload,
  Notification,
} from '@admin/features/layout/types/Notification';
import { DEFAULT_NOTIFICATION } from '@admin/features/layout/defs';
import objectHash from 'object-hash';

export const createNotification = (
  notification: CreateNotificationPayload
): Notification => {
  const variant: string = notification.variant ?? DEFAULT_NOTIFICATION.variant!;
  return {
    ...DEFAULT_NOTIFICATION,
    id: objectHash(notification),
    title:
      notification.title ?? variant.charAt(0).toUpperCase() + variant.slice(1),
    ...notification,
  } as Notification;
};
