/* eslint-disable */
import { api, queryClient } from '@admin/features/api'
import { FileResponseDto } from '@admin/features/api/generated'
import { CreateFolder } from '@admin/features/assets/components/CreateFolder/CreateFolder'
import {
  createFileRoute,
  useLoaderData,
  redirect,
  useParams,
} from '@tanstack/react-router'

const Component: React.FC = () => {
  const params = useParams({ from: '/_layout/assets/folder_/$folderId/create' })
  const loaderData = useLoaderData({
    from: '/_layout/assets/folder_/$folderId/create',
  })
  const [folderInfo, folderData] = loaderData as unknown as [
    FileResponseDto,
    FileResponseDto[],
  ]
  return (
    <CreateFolder folderInfo={folderInfo} folderData={folderData} {...params} />
  )
}

export const Route = createFileRoute(
  '/_layout/assets/folder_/$folderId/create',
)({
  loader: async ({ params: { folderId } }) => {
    if (folderId === 'root') {
      const folderData = await queryClient.fetchQuery({
        queryKey: ['root-folder-data'],
        queryFn: () => api.directoryControllerScanRootDir(),
      })

      const folderInfo = {
        path: '/',
        name: '',
      }

      return [folderInfo, folderData.data]
    }

    const folderInfo = await queryClient.fetchQuery({
      queryKey: ['single-folder-info', folderId],
      queryFn: () => api.fileControllerGetFile(folderId),
    })
    const folderData = await queryClient.fetchQuery({
      queryKey: ['single-folder-data', folderId],
      queryFn: () => api.directoryControllerScanDir(folderId),
    })
    return [folderInfo.data, folderData.data]
  },
  beforeLoad: ({ context }) => {
    const { isAuthenticated } = context.auth
    if (!isAuthenticated) {
      return redirect({ to: '/' })
    }
  },
  component: Component,
})
