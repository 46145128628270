import { ButtonHTMLAttributes, forwardRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { api } from '@admin/features/api';
import { Modal } from '@admin/features/theme/components/Modals/Modal';
import { FileResponseDto } from '@admin/features/api/generated';
import { Loader } from '@admin/features/theme/components/Loader/Loader';
import { EmptyListAlert } from '@admin/features/layout/components/PageTemplate/EmptyListAlert';
import { Directory } from '@admin/features/assets/components/Items/ChangeFolderModal/Directory';
import {
  PolymorphicComponentPropsWithRef,
  PolymorphicRef,
} from '@admin/utils/polymorphic';
import { isFolder } from '@admin/features/assets/utils/filter';

export type ChangeFolderModalProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  onFolderSelect?(
    file: FileResponseDto | Partial<FileResponseDto> | null
  ): void;
};

type PolymorphicChangeFolderModalProps<
  C extends React.ElementType,
  B extends ChangeFolderModalProps = ChangeFolderModalProps,
> = PolymorphicComponentPropsWithRef<C, B>;

type ChangeFolderModalComponent<
  P = unknown,
  B extends ChangeFolderModalProps = ChangeFolderModalProps,
> = <C extends React.ElementType = 'button'>(
  props: PolymorphicChangeFolderModalProps<C, B> & P
) => React.ReactNode | null;

export const ChangeFolderModal: ChangeFolderModalComponent = forwardRef(
  <T extends React.ElementType, R extends PolymorphicRef<T>>(
    { as, onFolderSelect, ...props }: PolymorphicChangeFolderModalProps<T>,
    ref?: R
  ) => {
    const Component = as ?? 'button';

    const { t } = useTranslation('assets');
    const [isOpen, setIsOpen] = useState(false);
    const [folders, setFolders] = useState<FileResponseDto[]>();
    const [history, setHistory] = useState<string[]>([]);
    const [selectedItem, setSelectedItem] = useState<FileResponseDto | null>(
      null
    );

    const {
      data: rootFolder,
      isLoading,
      isError,
      isSuccess,
      refetch,
    } = useQuery({
      queryKey: ['change-folder-root'],
      queryFn: () => api.directoryControllerScanRootDir(),
    });

    useEffect(() => {
      const mockRootFolder: Partial<FileResponseDto> = {
        id: 'root',
        name: '.',
        path: '/',
        fullPath: '/',
      };
      const allFolders = rootFolder?.data.filter(isFolder);
      allFolders?.unshift(mockRootFolder as FileResponseDto);
      setFolders(allFolders);
    }, [rootFolder]);

    const changeFolder = async (id: string): Promise<void> => {
      const resp = await api.directoryControllerScanDir(id);
      const allFolders = resp.data.filter(isFolder);
      setFolders(allFolders);
      setSelectedItem(null);
    };

    const goBackInHistory = async (): Promise<void> => {
      const id = history.at(-2);
      if (id) {
        await changeFolder(id);
      } else {
        await refetch();
      }
      setHistory((prev) => prev.slice(0, -1));
    };

    const handleClose = (): void => {
      setIsOpen(false);
    };

    const handleOpen = (): void => {
      setIsOpen(true);
    };

    const handleSelect = (): void => {
      onFolderSelect?.(selectedItem);
      setIsOpen(false);
    };

    const handleFolderDoubleClick = async (id: string): Promise<void> => {
      setHistory((prev) => [...prev, id]);
      await changeFolder(id);
    };

    const handleFolderClick = (item: FileResponseDto): void => {
      setSelectedItem((prev) => {
        if (!prev || prev.id !== item.id) {
          return item;
        }
        return null;
      });
    };

    return (
      <>
        <Modal
          isOpen={isOpen}
          onClose={handleClose}
          onConfirm={handleSelect}
          onPrevious={history.length === 0 ? handleClose : goBackInHistory}
          title={t('change.folder.title')}
          closeOnOutsideClick
          size='medium'
        >
          <div className='grid grid-cols-4 gap-2 mt-4'>
            {isLoading && (
              <div className='col-span-full'>
                <Loader className='col-span-full' />
              </div>
            )}
            {isError && (
              <h1 className='text-red-600 text-center col-span-full'>
                {t('folders.fetch.error')}
              </h1>
            )}

            {isSuccess && (folders ?? []).length > 0 ? (
              <div className='col-span-full'>
                <div className='grid grid-cols-4 gap-2 col-span-full'>
                  {folders?.map((item) => (
                    <Directory
                      key={item.id}
                      selected={item.id === selectedItem?.id}
                      onClick={() => {
                        handleFolderClick(item);
                      }}
                      onDoubleClick={async () => {
                        await handleFolderDoubleClick(item.id);
                      }}
                      {...item}
                    />
                  ))}
                </div>
              </div>
            ) : (
              <div className='col-span-full'>
                <EmptyListAlert />
              </div>
            )}
          </div>
        </Modal>
        <Component {...props} onClick={handleOpen} ref={ref} />
      </>
    );
  }
);
