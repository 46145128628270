import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { useQueryClient } from '@tanstack/react-query';
import * as yup from 'yup';
import { InferType } from 'yup';

import { api } from '@admin/features/api';
import { useNotificationDispatch } from '@admin/features/layout/hooks/useNotificationDispatch';
import { useHandledMutation } from '@admin/hooks/useHandledMutation';
import { FileResponseDto } from '@admin/features/api/generated';
import { pathResolve } from '@admin/features/assets/utils/path';
import { InputField } from '@admin/features/form/components/InputField';
import { useYupValidationResolver } from '@admin/hooks/useYupValidationResolver';
import { PageTemplate } from '@admin/features/layout/components/PageTemplate/PageTemplate';
import { Button } from '@admin/features/theme/components/Button/Button';
import { useRouter } from '@tanstack/react-router';
import { CreateFolderBreadcrumbs } from '../Breadcrumbs/CreateFolderBreadcrumbs';

type CreateFolderProps = {
  folderId: string;
  folderInfo: FileResponseDto;
  folderData: FileResponseDto[];
};

const schema = yup.object({
  name: yup.string().required().min(2).ensure(),
});
type FormValues = InferType<typeof schema>;

export const CreateFolder: React.FC<CreateFolderProps> = ({
  folderId,
  folderInfo,
  folderData,
}) => {
  const router = useRouter();
  const { t } = useTranslation('assets');
  const resolver = useYupValidationResolver(schema);
  const methods = useForm({
    resolver,
    defaultValues: schema.cast({}),
  });
  const { handleSubmit, setError } = methods;
  const queryClient = useQueryClient();
  const createNotification = useNotificationDispatch();

  const { mutate, isPending } = useHandledMutation({
    mutationFn: (path: string) =>
      api.directoryControllerCreateDirectory({ path }),
    onSuccess: async (data) => {
      const { name } = data.data;
      createNotification({
        content: t('folders.create.success.alert', {
          folderName: name,
          interpolation: { escapeValue: false },
        }),
        variant: 'success',
      });

      if (folderId === 'root') {
        await queryClient.invalidateQueries({
          queryKey: ['all-folders'],
        });
      } else {
        await queryClient.invalidateQueries({
          queryKey: ['single-folder', folderId],
        });
      }
      router.history.back();
    },
    onError: ({ name }: FormValues) => {
      createNotification({
        content: t('folders.create.error.alert', {
          folderName: name,
          interpolation: { escapeValue: false },
        }),
        variant: 'error',
      });
    },
  });

  const handleCreate = handleSubmit((values: FormValues) => {
    const newFolderName = values.name;
    const existingFolders = folderData
      .filter((file) => file.type === 'directory')
      .map((item) => item.name.toLowerCase());

    const isValidName = !existingFolders.some(
      (name) => name === values.name.toLowerCase()
    );
    if (!isValidName) {
      setError('name', { message: t('folders.create.error.already.exist') });
      return;
    }
    const newFolderPath = pathResolve(
      folderInfo.path,
      folderInfo.name,
      newFolderName
    );
    mutate(newFolderPath);
  });

  return (
    <PageTemplate
      title={t('folders.create.title')}
      breadcrumbs={<CreateFolderBreadcrumbs folderInfo={folderInfo} />}
    >
      <p className='text-xs text-gray-600'>
        {t('folders.create.description', {
          folderPath: pathResolve(folderInfo.path, folderInfo.name),
          interpolation: { escapeValue: false },
        })}
      </p>
      <FormProvider {...methods}>
        <div className='space-y-4'>
          <InputField
            name='name'
            label={t('folders.create.label')}
            placeholder={t('folders.create.placeholder')}
          />
          <Button onClick={handleCreate} className='w-full' loading={isPending}>
            {t('folders.create.btn')}
          </Button>
        </div>
      </FormProvider>
    </PageTemplate>
  );
};
