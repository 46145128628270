import React from 'react';
import { cn } from '@admin/utils/cn';
import { UserHierarchyNodeDto } from '@admin/features/api/generated';
import { Separator } from '@admin/features/theme/components/Separator/Separator';
import { useSortable } from '@dnd-kit/sortable';
import { User } from './User';
import { Catcher } from './Catcher';

type UserListProps = {
  id: string;
  className?: string;
  children?: React.ReactNode;
  users: UserHierarchyNodeDto[];
};

/* eslint-disable-next-line @typescript-eslint/explicit-function-return-type */
const listAllUsers = (users: UserHierarchyNodeDto[], layer: number) => {
  const listUser = (
    user: UserHierarchyNodeDto,
    parentId: string | null,
    layer: number,
    usersIndex: number,
    users: UserHierarchyNodeDto[]
  ): React.ReactElement => {
    const isFirst = usersIndex === 0;
    const isLast = usersIndex === users.length - 1;
    const topCatcherIndexBase = usersIndex - 1 <= 0 ? 0 : usersIndex - 1;
    const topCatcherIndex = !isFirst
      ? topCatcherIndexBase + 1
      : topCatcherIndexBase;

    return (
      <div key={user.id} className='grid grid-col-1 w-full'>
        <div className='flex w-full space-x-5'>
          {Array(layer)
            .fill(null)
            .map((_, index) => (
              <Separator key={index} orientation='vertical' />
            ))}
          <div className='flex flex-col w-full'>
            <Catcher
              user={user}
              parentId={parentId}
              moveTo={topCatcherIndex}
              users={users}
              direction='upper'
            />
            <User user={user} level={layer} parentId={parentId} />
            {isLast && (
              <Catcher
                user={user}
                parentId={parentId}
                moveTo={usersIndex + 1}
                users={users}
                direction='lower'
              />
            )}
          </div>
        </div>
        {user.subordinates.map((subordinate, index) =>
          listUser(subordinate, user.id, layer + 1, index, user.subordinates)
        )}
      </div>
    );
  };

  return users.map((user, index) => {
    return listUser(user, null, layer, index, users);
  });
};

export const UserList: React.FC<UserListProps> = ({ id, users, className }) => {
  const { setNodeRef } = useSortable({ id });

  return (
    <div className={cn(` flex`, className)} ref={setNodeRef}>
      {listAllUsers(users, 0)}
    </div>
  );
};
