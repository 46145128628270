import { QueryClient, useMutation } from '@tanstack/react-query';
import type {
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query/src/types';
import type { DefaultError } from '@tanstack/query-core';
import { useNotificationDispatch } from '@admin/features/layout/hooks/useNotificationDispatch';
import { uuid } from '@admin/adapters/uuid';
import { useTranslation } from 'react-i18next';

export const useHandledMutation = <
  TData = unknown,
  TError = DefaultError,
  TVariables = void,
  TContext = unknown,
>(
  options: UseMutationOptions<TData, TError, TVariables, TContext>,
  queryClient?: QueryClient
): UseMutationResult<TData, TError, TVariables, TContext> => {
  const dispatchNotification = useNotificationDispatch();
  const { t } = useTranslation('layout');
  return useMutation(
    {
      ...options,
      onError: (err, vars, ctx) => {
        dispatchNotification({
          id: uuid(),
          variant: 'error',
          title: t('notifications.error.title'),
          content: t('notifications.error.content'),
        });
        return options.onError?.(err, vars, ctx);
      },
    },
    queryClient
  );
};
