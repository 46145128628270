import { useCallback } from 'react';
import { FieldValues, Resolver, ResolverResult } from 'react-hook-form';
import * as yup from 'yup';
import { ValidationError } from 'yup';

export const useYupValidationResolver = <T extends FieldValues>(
  validationSchema: yup.Schema<T>
): Resolver<T> =>
  useCallback(
    async (data: T): Promise<ResolverResult<T>> => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
        });

        return {
          values,
          errors: {},
        };
      } catch (errors) {
        return {
          values: {},
          errors: (errors as ValidationError | undefined)?.inner.reduce(
            (allErrors: ValidationError, currentError: ValidationError) => ({
              ...allErrors,
              [currentError.path ?? currentError.type ?? '']: {
                type: currentError.type ?? 'validation',
                message: currentError.message,
              },
            }),
            {}
          ),
        };
      }
    },
    [validationSchema]
  );
