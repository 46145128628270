import { ListboxOption } from '@headlessui/react';
import React, { PropsWithChildren } from 'react';
import { DropdownOption as TDropdownOption } from '@admin/features/theme/components/SelectLanguage/SelectLanguage';

type DropdownOptionProps<T extends string = string> = {
  value: TDropdownOption<T>;
};

export const DropdownOption: React.FC<
  PropsWithChildren<DropdownOptionProps>
> = ({ children, value }) => {
  return (
    <ListboxOption
      value={value}
      className='flex align-center gap-2 rounded-lg py-2 p-2 text-sm font-medium text-gray-900 rounded data-[focus]:bg-blue-100 cursor-pointer'
    >
      {children}
    </ListboxOption>
  );
};
