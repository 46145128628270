import { useMemo } from 'react';
import { UserLevelAccessRole, UserWithPermissionsDto } from '../api/generated';
import { useQuery } from '@tanstack/react-query';
import { api } from '../api';

export type UsePermissionsReturn = {
  permissions?: UserWithPermissionsDto;
  user?: UserWithPermissionsDto;
  isAdmin: boolean;
  isChief: boolean;
  isEditor: boolean;
};

export const usePermissions = (): UsePermissionsReturn => {
  const { data } = useQuery({
    queryKey: ['userControllerFindPermissions'],
    queryFn: async () => {
      const { data } = await api.userControllerGetCurrent();
      return await api.userControllerFindPermissions(data.id);
    },
  });

  const permissions = data?.data;

  const { isAdmin, isChief, isEditor } = useMemo(
    () => ({
      isAdmin:
        permissions?.userLevelAccessRole === UserLevelAccessRole.UserAdmin,
      isChief:
        permissions?.userLevelAccessRole === UserLevelAccessRole.UserChief,
      isEditor:
        permissions?.userLevelAccessRole === UserLevelAccessRole.UserEditor,
    }),
    [permissions?.userLevelAccessRole]
  );

  return {
    permissions,
    user: permissions,
    isAdmin,
    isChief,
    isEditor,
  };
};
