import { TagResponseDto } from '@admin/features/api/generated';
import { FC, MouseEventHandler } from 'react';
import { useTranslatedContent } from '@admin/hooks/useTranslatedContent';
import { TagGroup } from './TagGroup';
import { rectSortingStrategy, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { cn } from '@admin/utils/cn';
import { FaPen, FaTrash } from 'react-icons/fa6';

export type TagProps = TagResponseDto & {
  draggable?: boolean;
  selected?: boolean;
  onUpdate?(tag: TagResponseDto): void;
  onRemove?(tag: TagResponseDto): void;
  onSelect?(tag: TagResponseDto): void;
};

export const PrivateTag: FC<TagProps> = (props) => {
  const {
    translations,
    color,
    id,
    draggable,
    onRemove,
    onUpdate,
    onSelect,
    selected,
  } = props;
  const translation = useTranslatedContent(translations)!;
  const { setNodeRef, attributes, listeners, transform } = useSortable({
    id,
    data: props,
    disabled: !draggable,
    strategy: rectSortingStrategy,
  });

  const removeHandler: MouseEventHandler<SVGElement> = (e): void => {
    e.stopPropagation();
    onRemove?.(props);
  };

  const updateHandler: MouseEventHandler<SVGElement> = (e): void => {
    e.stopPropagation();
    onUpdate?.(props);
  };

  const selectHandler: MouseEventHandler<HTMLSpanElement> = (e): void => {
    e.stopPropagation();
    onSelect?.(props);
  };

  return (
    <span
      ref={setNodeRef}
      style={{
        transform: CSS.Transform.toString(transform),
      }}
      className='flex rounded'
    >
      <span
        className={cn(
          'inline-flex items-center px-2 py-1 text-sm font-medium rounded gap-1'
        )}
        style={
          onSelect && selected
            ? {
                backgroundColor: color,
                borderColor: color,
                borderWidth: 2,
                color: 'white',
              }
            : onSelect && !selected
              ? {
                  borderColor: color,
                  borderWidth: 2,
                  color,
                }
              : {
                  borderColor: color,
                  borderWidth: 2,
                  color,
                }
        }
        {...attributes}
        {...listeners}
        onClick={selectHandler}
      >
        {translation.name}
        <span className='flex rounded-br rounded-tr items-center'>
          {onUpdate && (
            <FaPen
              className='cursor-pointer mx-1 text-xs'
              onClick={updateHandler}
            />
          )}
          {onRemove && (
            <FaTrash
              className='cursor-pointer mx-1 text-xs'
              onClick={removeHandler}
            />
          )}
        </span>
      </span>
    </span>
  );
};

export const Tag = Object.assign(PrivateTag, {
  Group: TagGroup,
});
