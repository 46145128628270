import { useTranslation } from 'react-i18next';
import { api } from '@admin/features/api';
import { useQuery } from '@tanstack/react-query';
import { Loader } from '@admin/features/theme/components/Loader/Loader';
import { usePermissions } from '@admin/features/auth/usePermissions';
import { PendingUser } from '@admin/features/users/components/UserManagement/components/Items/PendingUsers/PendingUser';
import { isPendingUser } from '@admin/features/users/components/UserManagement/utils/filter';

export const PendingUsers: React.FC = () => {
  const { t } = useTranslation('users');
  const { isAdmin } = usePermissions();

  const { data, isLoading, isError, isSuccess } = useQuery({
    queryKey: ['all-users', 'pending'],
    queryFn: () => api.userControllerFindAll(),
  });

  const pendingUsers = data?.data.filter(isPendingUser) ?? [];

  return (
    <div className='space-y-4'>
      {isAdmin && (
        <>
          <h1 className='font-semibold text-xl'>
            {t('list.users.pending.title')}
          </h1>
          {isLoading && <Loader />}
          {isError && (
            <p className='text-red-600'>{t('list.users.pending.error')}</p>
          )}
          {isSuccess && pendingUsers.length !== 0 ? (
            <div className='w-full'>
              <div className='bg-orange-50 border-l-4 space-y-2 border-orange-500 p-2'>
                {pendingUsers.map((user) => (
                  <PendingUser user={user} />
                ))}
              </div>
            </div>
          ) : (
            <h1>{t('list.users.pending.no.found')}</h1>
          )}
        </>
      )}
    </div>
  );
};
