import * as React from 'react';
import { FC, ReactNode, useMemo } from 'react';
import { cn } from '@admin/utils/cn';
import {
  Button,
  ButtonProps,
  buttonVariants,
} from '@admin/features/theme/components/Button/Button';
import { Link, LinkProps } from '@tanstack/react-router';
import { FaChevronLeft, FaEllipsis } from 'react-icons/fa6';
import { FaChevronRight } from 'react-icons/fa';

type PaginationBodyProps = React.ComponentProps<'nav'>;

const PaginationBody = ({ className, ...props }: PaginationBodyProps) => (
  <nav
    role='navigation'
    aria-label='pagination'
    className={cn('mx-auto flex w-full justify-center', className)}
    {...props}
  />
);
PaginationBody.displayName = 'PaginationBody';

const PaginationContent = React.forwardRef<
  HTMLUListElement,
  React.ComponentProps<'ul'>
>(({ className, ...props }, ref) => (
  <ul
    ref={ref}
    className={cn('flex flex-row items-center gap-1', className)}
    {...props}
  />
));
PaginationContent.displayName = 'PaginationContent';

const PaginationItem = React.forwardRef<
  HTMLLIElement,
  React.ComponentProps<'li'>
>(({ className, ...props }, ref) => (
  <li ref={ref} className={cn('', className)} {...props} />
));
PaginationItem.displayName = 'PaginationItem';

type PaginationLinkProps = {
  isActive?: boolean;
} & Pick<ButtonProps, 'size' | 'className'> &
  (LinkProps | ButtonProps);

const PaginationLink = ({
  className,
  isActive,
  size = 'icon',
  ...props
}: PaginationLinkProps) =>
  'to' in props ? (
    <Link
      aria-current={isActive ? 'page' : undefined}
      className={cn(
        buttonVariants({
          variant: isActive ? 'outline' : 'ghost',
          size,
        }),
        className
      )}
      {...props}
    />
  ) : (
    <Button
      {...(props as ButtonProps)}
      size={size ?? undefined}
      variant={isActive ? 'outline' : 'ghost'}
      className={className}
    />
  );
PaginationLink.displayName = 'PaginationLink';

const PaginationPrevious = ({
  className,
  ...props
}: React.ComponentProps<typeof PaginationLink>) => (
  <PaginationLink
    aria-label='Go to previous page'
    size='default'
    className={cn('gap-1 pl-2.5', className)}
    {...props}
  >
    <FaChevronLeft className='h-4 w-4' />
  </PaginationLink>
);
PaginationPrevious.displayName = 'PaginationPrevious';

const PaginationNext = ({
  className,
  ...props
}: React.ComponentProps<typeof PaginationLink>) => (
  <PaginationLink
    aria-label='Go to next page'
    size='default'
    className={cn('gap-1 pr-2.5', className)}
    {...props}
  >
    <FaChevronRight className='h-4 w-4' />
  </PaginationLink>
);
PaginationNext.displayName = 'PaginationNext';

const PaginationEllipsis = ({
  className,
  ...props
}: React.ComponentProps<'span'>) => (
  <span
    aria-hidden
    className={cn('flex h-9 w-9 items-center justify-center', className)}
    {...props}
  >
    <FaEllipsis className='h-4 w-4' />
  </span>
);
PaginationEllipsis.displayName = 'PaginationEllipsis';

export type PaginationProps = {
  totalItems?: number;
  page?: number;
  limit?: number;
  /**
   * Determines whether to use links or state
   */
  controlled?: boolean;
  onPageChange?(page: number): void;
};

const Pagination: FC<PaginationProps> = ({
  totalItems = 0,
  page: pageProp = 0,
  limit = 10,
  controlled,
  onPageChange,
}) => {
  const totalPages = Math.ceil(totalItems / limit);
  const page = useMemo(() => {
    if (controlled) {
      return pageProp;
    }
    const url = new URL(window.location.href);
    return parseInt(url.searchParams.get('page') ?? '0') || 0;
  }, [controlled, pageProp]);
  const pages = useMemo(() => {
    const result: ReactNode[] = [];
    const getItemProps = (page: number) => {
      if (controlled) {
        return {
          onClick: () => onPageChange?.(page),
        };
      }
      const url = new URL(window.location.href);
      url.searchParams.set('page', page.toString());
      return { to: url.toString(), key: page };
    };
    const getPaginationLink = (p: number) => (
      <PaginationLink
        {...getItemProps(p)}
        isActive={p === page}
        disabled={p === page}
        key={p.toString()}
      >
        {p + 1}
      </PaginationLink>
    );
    if (totalPages <= 1) {
      return result;
    }
    if (page > 0) {
      result.push(
        <PaginationPrevious {...getItemProps(page - 1)} key='prev' />
      );
    }

    const intermediate = [0];
    if (totalPages > 1) {
      intermediate.push(totalPages - 1);
    }
    const visibleSurrounding = 2;
    const pages = Array.from({ length: visibleSurrounding * 2 + 1 })
      .map((_, i) => page - visibleSurrounding + i)
      .filter(
        (p) => !intermediate.includes(p) && p >= 0 && p <= totalPages - 1
      );
    result.push(getPaginationLink(intermediate[0]));
    if (pages[0] - intermediate[0] > 1) {
      result.push(<PaginationEllipsis key='ellipsis-left' />);
    }
    result.push(...pages.map((p) => getPaginationLink(p)));
    if (
      intermediate.length > 1 &&
      intermediate[1] - pages[pages.length - 1] > 1
    ) {
      result.push(<PaginationEllipsis key='ellipsis-right' />);
    }
    if (intermediate.length > 1) {
      result.push(getPaginationLink(intermediate[1]));
    }

    if (page < totalPages - 1) {
      result.push(<PaginationNext {...getItemProps(page + 1)} key='next' />);
    }
    return result;
  }, [controlled, onPageChange, page, totalPages]);
  return (
    <PaginationBody>
      <PaginationContent>{pages}</PaginationContent>
    </PaginationBody>
  );
};

export {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
};
