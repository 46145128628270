import ReactDOM from 'react-dom/client';

import App from '@admin/App';
import '@admin/init';

// Render the app

const rootElement = document.getElementById('root')!;

if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(<App />);
}
