import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@admin/features/theme/components/Form/Form';
import {
  Input,
  InputProps,
} from '@admin/features/theme/components/Input/Input';
import { TextareaProps } from '@headlessui/react';
import { Textarea } from '@admin/features/theme/components/Textarea/Textarea';

export type InputFieldProps = {
  label?: string;
  name: string;
  formItemClassName?: string;
  children?: never;
  className?: string;
} & (
  | (Omit<InputProps, 'className'> & { multiline?: false })
  | (Omit<TextareaProps, 'className'> & { multiline: true })
);

export const InputField: FC<InputFieldProps> = ({
  label,
  name,
  formItemClassName,
  ...props
}) => {
  const { control } = useFormContext();

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => {
        const handleChange: React.ChangeEventHandler<
          HTMLInputElement | HTMLTextAreaElement
        > = (e) => {
          props.onChange?.(e as never);
          field.onChange(e);
        };
        return (
          <FormItem className={formItemClassName}>
            {label && <FormLabel>{label}</FormLabel>}
            <FormControl>
              {props.multiline ? (
                <Textarea {...field} {...props} onChange={handleChange} />
              ) : (
                <Input {...field} {...props} onChange={handleChange} />
              )}
            </FormControl>
            <FormMessage />
          </FormItem>
        );
      }}
    />
  );
};
