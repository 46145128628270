import { Link } from '@tanstack/react-router';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaBox, FaGear, FaUpload, FaUsersGear } from 'react-icons/fa6';

type SidebarItemProps = {
  to: string;
  text: string;
  icon: React.ReactNode;
};

const SidebarItem: React.FC<SidebarItemProps> = ({ icon, to, text }) => {
  return (
    <Link
      to={to}
      className='flex items-center p-4 space-x-4 hover:bg-black text-lg hover:text-white cursor-pointer'
    >
      {icon}
      <span className='w-full truncate'>{text}</span>
    </Link>
  );
};

export const Sidebar: React.FC = () => {
  const { t } = useTranslation('layout');

  return (
    <div className='flex flex-col border-r-2 w-72 max-w-72 h-full'>
      <SidebarItem
        icon={<FaBox />}
        to='/blogs'
        text={t('sidebar.item.blogs')}
      />
      <SidebarItem
        icon={<FaUsersGear />}
        to='/users/'
        text={t('sidebar.item.users')}
      />
      <SidebarItem
        icon={<FaUpload />}
        to='/assets'
        text={t('sidebar.item.assets')}
      />
      {/* <SidebarItem icon={<FaGear />} to='/' text={t('sidebar.item.settings')} /> */}
    </div>
  );
};
