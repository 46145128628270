import {
  TypedUseSelectorHook,
  useDispatch as usePrivateDispatch,
  useSelector as usePrivateSelector,
} from 'react-redux';

import env from '@admin/env';
import { configureStore } from '@reduxjs/toolkit';
import { authReducer } from '@admin/features/auth/state/authSlice';
import {
  closeNotificationListenerMiddleware,
  createNotificationListenerMiddleware,
} from '@admin/features/layout/state/middlewares';
import { layoutReducer } from '@admin/features/layout/state/layoutSlice';
import { modalReducer } from '@admin/features/layout/modals/modalSlice';
import { editorReducer } from '@admin/features/blogs/BlogEditor/state/editorSlice';

const isProduction = env.nodeEnv === 'production';
export const store = configureStore({
  reducer: {
    auth: authReducer,
    layout: layoutReducer,
    modal: modalReducer,
    editor: editorReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: isProduction,
      serializableCheck: isProduction,
    }).prepend(
      createNotificationListenerMiddleware.middleware,
      closeNotificationListenerMiddleware.middleware
    ),
  devTools: !isProduction,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useDispatch = (): AppDispatch => usePrivateDispatch<AppDispatch>();
export const useSelector: TypedUseSelectorHook<RootState> = usePrivateSelector;
