import { cn } from '@admin/utils/cn';
import { useDroppable } from '@dnd-kit/core';
import React, { PropsWithChildren } from 'react';

type DroppableTagsAreaProps = {
  id: string;
};

export const DroppableTagsArea: React.FC<
  PropsWithChildren<DroppableTagsAreaProps>
> = ({ id, children }) => {
  const { setNodeRef, isOver } = useDroppable({ id });
  return (
    <div
      ref={setNodeRef}
      style={{ height: 80 }}
      className={cn(
        isOver ? 'border-2 border-blue-600' : 'border-2 border-transparent-600',
        'bg-slate-100 p-2 rounded'
      )}
    >
      {children}
    </div>
  );
};
