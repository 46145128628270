import * as yup from 'yup';
import { UserHierarchyNodeDto } from '@admin/features/api/generated';
import { InferType } from 'yup';
import { useForm } from '@admin/hooks/useForm';
import { useTranslation } from 'react-i18next';
import { useHandledMutation } from '@admin/hooks/useHandledMutation';
import { api } from '@admin/features/api';
import { useNotificationDispatch } from '@admin/features/layout/hooks/useNotificationDispatch';
import { useQueryClient } from '@tanstack/react-query';
import { FormProvider } from 'react-hook-form';
import { Modal } from '@admin/features/theme/components/Modals/Modal';
import { InputField } from '@admin/features/form/components/InputField';

const schema = yup.object({
  firstName: yup.string().required().default(''),
  lastName: yup.string().required().default(''),
});
type FormValues = InferType<typeof schema>;

export type UserNameModalProps = {
  onClose(): void;
  isOpen: boolean;
  user: UserHierarchyNodeDto;
};

export const UserNameModal: React.FC<UserNameModalProps> = ({
  onClose,
  user,
  isOpen,
}) => {
  const { t } = useTranslation('users');
  const methods = useForm({
    schema,
    defaultValues: schema.cast({
      firstName: user.firstName || '',
      lastName: user.lastName || '',
    }),
  });
  const notificationDispatch = useNotificationDispatch();

  const client = useQueryClient();
  const {
    mutate: updateName,
    isPending: isSavingUserName,
    isError,
  } = useHandledMutation({
    mutationKey: ['userControllerSetUserName'],
    mutationFn: (values: FormValues) => api.userControllerSetUserName(values),
    onSuccess: async () => {
      notificationDispatch({
        content: t('list.modal.manage.success.alert', {
          user: user.fullName,
        }),
        variant: 'success',
      });
      await client.invalidateQueries({
        queryKey: ['all-users'],
      });
      onClose();
    },
  });

  const handleManageModalSave = (): void => {
    const values = methods.getValues();
    updateName(values);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={handleManageModalSave}
      size='small'
      title={t('list.modal.name.user.title', { user: user.fullName })}
      loading={isSavingUserName}
    >
      {isError && (
        <p className='text-red-600'>{t('list.modal.manage.error.msg')}</p>
      )}
      <FormProvider {...methods}>
        <div className='flex flex-col space-y-4'>
          <InputField
            name='firstName'
            label={t('list.modal.name.form.firstName.label')}
            placeholder={t('list.modal.name.form.firstName.placeholder')}
          />
          <InputField
            name='lastName'
            label={t('list.modal.name.form.lastName.label')}
            placeholder={t('list.modal.name.form.lastName.placeholder')}
          />
        </div>
      </FormProvider>
    </Modal>
  );
};
