import { Language } from '@admin/features/api/generated';
import i18next from 'i18next';
import { useMemo } from 'react';

export const getTranslatedContent = <T extends { language: Language }>(
  translations: T[],
  fallback = Language.En
): T | undefined => {
  const language = i18next.language as Language;
  return (
    translations.find((t) => t.language === language) ??
    translations.find((t) => t.language === fallback) ??
    translations[0]
  );
};

export const useTranslatedContent = <T extends { language: Language }>(
  translations: T[],
  fallback = Language.En
): T | undefined => {
  return useMemo(
    () => getTranslatedContent(translations, fallback),
    [fallback, translations]
  );
};
