import React from 'react';

import { routeTree } from '@admin/routeTree.gen';
import { useSelector } from '@admin/store/store';
import { RouterProvider, createRouter } from '@tanstack/react-router';

const router = createRouter({
  routeTree,
  context: { auth: undefined! },
});

// Register the router instance for type safety
declare module '@tanstack/react-router' {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Register {
    router: typeof router;
  }
}

const AppNavigator: React.FC = () => {
  const auth = useSelector((store) => store.auth);

  return <RouterProvider router={router} context={{ auth }} />;
};

export default AppNavigator;
