import update from 'immutability-helper';

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { storage } from '@admin/storage';

type AuthState = {
  isAuthenticated: boolean;
};

const initialState: AuthState = {
  isAuthenticated: Boolean(storage.read('auth')),
};

const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    setIsAuthenticated: (state, action: PayloadAction<boolean>) =>
      update(state, {
        isAuthenticated: {
          $set: action.payload,
        },
      }),
  },
});

export const authActions = authSlice.actions;

export const authReducer = authSlice.reducer;
