import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { i18nResources } from '@admin/locales/i18nResources';

void i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: 'en',
    resources: i18nResources,
    ns: Object.keys(i18nResources.en),
    defaultNS: 'common',
  });

export default i18n;
