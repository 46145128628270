import { FileResponseDto } from '@admin/features/api/generated';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from '@admin/features/theme/components/Breadcrumb/Breadcrumb';
import { Link } from '@tanstack/react-router';
import React from 'react';
import { useTranslation } from 'react-i18next';

type SingleFolderBreadcrumbsProps = {
  folderInfo: FileResponseDto;
  parentFolderData: FileResponseDto | undefined;
  isLoading: boolean;
};

export const SingleFolderBreadcrumbs: React.FC<
  SingleFolderBreadcrumbsProps
> = ({ folderInfo, parentFolderData, isLoading }) => {
  const { t } = useTranslation('assets');

  return (
    <Breadcrumb>
      <BreadcrumbList className='select-none'>
        <BreadcrumbItem>
          <BreadcrumbLink>
            <Link to='/assets'>{t('folders.breadcrumb.root')}</Link>
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbSeparator />
        {isLoading && '...'}
        {parentFolderData && (
          <>
            <BreadcrumbItem>
              <BreadcrumbLink>
                <Link to={`/assets/folder/${folderInfo.parentDirectoryId}`}>
                  {parentFolderData.name}
                </Link>
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
          </>
        )}
        <BreadcrumbItem className='font-bold'>{folderInfo.name}</BreadcrumbItem>
      </BreadcrumbList>
    </Breadcrumb>
  );
};
