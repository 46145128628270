import React from 'react';
import { HexColorPicker } from 'react-colorful';
import { FormField, FormItem, FormLabel } from '../Form/Form';
import { useFormContext } from 'react-hook-form';

type ColorPickerProps = {
  name: string;
  label?: string;
};

export const ColorPicker: React.FC<ColorPickerProps> = ({ name, label }) => {
  const { setValue, control, watch } = useFormContext();

  const changeHandler = (color: string): void => {
    setValue(name, color);
  };

  const color = watch(name);

  return (
    <FormField
      control={control}
      name={name}
      render={() => (
        <FormItem>
          {label && <FormLabel>{label}</FormLabel>}
          <HexColorPicker color={color} onChange={changeHandler} />
        </FormItem>
      )}
    />
  );
};
