import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from '@tanstack/react-router';

import { Button } from '@admin/features/theme/components/Button/Button';
import { api } from '@admin/features/api';
import { storage } from '@admin/storage';
import { useDispatch } from '@admin/store/store';
import { authActions } from '@admin/features/auth/state/authSlice';
import { useHandledMutation } from '@admin/hooks/useHandledMutation';

export const Navbar: React.FC = () => {
  const { t } = useTranslation('layout');
  const dispatch = useDispatch();

  const { isPending, mutate } = useHandledMutation({
    mutationFn: () => api.authControllerLogout(),
    onSuccess: () => {
      dispatch(authActions.setIsAuthenticated(false));
      storage.remove('auth');
      // eslint-disable-next-line no-warning-comments
      // todo: resolve this because tanstack doesn't want to redirect (🤦‍♂️)
      window.location.reload();
    },
  });

  const handleLogout = (): void => {
    mutate();
  };

  // eslint-disable-next-line no-warning-comments
  // TODO I am counting on that layout/navbar will be only shown to the logged users ,so there is no check if user is logged

  return (
    <div className='flex items-center justify-between p-4 h-[10vh] border-b-2'>
      <h1 className='font-bold text-xl'>{t('navbar.company.name')}</h1>
      <div className='flex items-center space-x-4'>
        <Link to='/'>{t('navbar.dashboard.label')}</Link>
        <Button onClick={handleLogout} loading={isPending}>
          {t('navbar.logout.button.text')}
        </Button>
      </div>
    </div>
  );
};
