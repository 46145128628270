/* eslint-disable */
import { api, queryClient } from '@admin/features/api'
import { FileResponseDto } from '@admin/features/api/generated'
import { UploadFileBreadcrumbs } from '@admin/features/assets/components/Breadcrumbs/UploadFileBreadcrumbs'
import { UploadFile } from '@admin/features/assets/components/UploadFile/UploadFile'
import { PageTemplate } from '@admin/features/layout/components/PageTemplate/PageTemplate'
import {
  createFileRoute,
  redirect,
  useLoaderData,
  useParams,
} from '@tanstack/react-router'
import { useTranslation } from 'react-i18next'

const Component: React.FC = () => {
  const { t } = useTranslation('assets')
  const params = useParams({ from: '/_layout/assets/folder_/$folderId/upload' })
  const loaderData = useLoaderData({
    from: '/_layout/assets/folder_/$folderId/upload',
  })
  const [folderInfo, folderData] = loaderData as unknown as [
    FileResponseDto,
    FileResponseDto[],
  ]
  return (
    <PageTemplate title={t('upload.title')} breadcrumbs={<UploadFileBreadcrumbs folderInfo={folderInfo} />}>
      <UploadFile
        {...params}
        folderData={folderData}
        path={folderInfo.fullPath}
      />
    </PageTemplate>
  )
}

export const Route = createFileRoute(
  '/_layout/assets/folder_/$folderId/upload',
)({
  loader: async ({ params: { folderId } }) => {
    if (folderId === 'root') {
      const folderData = await queryClient.fetchQuery({
        queryKey: ['root-folder-data'],
        queryFn: () => api.directoryControllerScanRootDir(),
      })

      const folderInfo = {
        path: '/',
        name: '',
        fullPath: '/',
      }

      return [folderInfo, folderData.data]
    }

    const folderInfo = await queryClient.fetchQuery({
      queryKey: ['single-folder-info', folderId],
      queryFn: () => api.fileControllerGetFile(folderId),
    })
    const folderData = await queryClient.fetchQuery({
      queryKey: ['single-folder-data', folderId],
      queryFn: () => api.directoryControllerScanDir(folderId),
    })
    return [folderInfo.data, folderData.data]
  },
  beforeLoad: ({ context }) => {
    const { isAuthenticated } = context.auth
    if (!isAuthenticated) {
      return redirect({ to: '/' })
    }
  },
  component: Component,
})
