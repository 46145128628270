import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from '@admin/features/theme/components/Breadcrumb/Breadcrumb';
import { cn } from '@admin/utils/cn';
import { Link, useRouter } from '@tanstack/react-router';

export const Breadcrumbs: React.FC = () => {
  const { routesByPath } = useRouter();
  const items = new URL(window.location.href).pathname
    .trim()
    .split('/')
    .filter(Boolean);
  let currentLink = '';

  return (
    <Breadcrumb>
      <BreadcrumbList>
        {items.map((item, index) => {
          currentLink += `/${item}`;
          const isSeparatorActive = index !== items.length - 1;
          const isValidRoute = Object.keys(routesByPath).includes(currentLink);
          const isLastItem = index === items.length - 1;

          return (
            <div
              key={index}
              className='flex select-none items-center space-x-2'
            >
              <BreadcrumbItem
                className={cn(isLastItem && 'font-semibold')}
                key={index}
              >
                {isValidRoute ? (
                  <BreadcrumbLink>
                    <Link to={currentLink}>{item}</Link>
                  </BreadcrumbLink>
                ) : (
                  <span>{item}</span>
                )}
              </BreadcrumbItem>
              {isSeparatorActive && <BreadcrumbSeparator />}
            </div>
          );
        })}
      </BreadcrumbList>
    </Breadcrumb>
  );
};
