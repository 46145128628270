import {
  Description,
  Field,
  Label,
  Select as HeadlessSelect,
} from '@headlessui/react';
import { FaChevronDown } from 'react-icons/fa6';
import { cn } from '@admin/utils/cn';

type SelectProps = {
  name: string;
  value?: string;
  label?: string;
  disabled?: boolean;
  description?: string;
};

export const Select: React.FC<
  SelectProps & React.HTMLAttributes<HTMLSelectElement>
> = ({
  name,
  label,
  value,
  onChange,
  description,
  disabled,
  children,
  className,
}) => {
  return (
    <div className={cn('w-full', className)}>
      <Field className={'flex flex-col space-y-1 w-full'} disabled={disabled}>
        {label && <Label>{label}</Label>}
        {description && (
          <Description className='text-xs text-gray-500'>
            {description}
          </Description>
        )}
        <div className='relative w-full'>
          <HeadlessSelect
            name={name}
            aria-label={name}
            onChange={onChange}
            value={value}
            className={
              'appearance-none rounded-lg border-2 py-2 px-4 w-full text-sm text-black *:text-black truncate'
            }
          >
            {children}
          </HeadlessSelect>
          <FaChevronDown
            className='pointer-events-none absolute top-2.5 right-2.5 size-4 text-black bg-white pl-1'
            aria-hidden='true'
          />
        </div>
      </Field>
    </div>
  );
};
