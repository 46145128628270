import { api } from '@admin/features/api';
import { InputField } from '@admin/features/form/components/InputField';
import { PageTemplate } from '@admin/features/layout/components/PageTemplate/PageTemplate';
import { Button } from '@admin/features/theme/components/Button/Button';
import { useForm } from '@admin/hooks/useForm';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { InferType } from 'yup';
import { useNotificationDispatch } from '@admin/features/layout/hooks/useNotificationDispatch';
import { useHandledMutation } from '@admin/hooks/useHandledMutation';
import { useQueryClient } from '@tanstack/react-query';
import { useRouter } from '@tanstack/react-router';

const schema = yup.object({
  email: yup.string().required().email().ensure(),
});
type FormValues = InferType<typeof schema>;

export const InviteUser: React.FC = () => {
  const { t } = useTranslation('users');

  const methods = useForm({
    schema,
    defaultValues: schema.cast({}),
  });

  const notify = useNotificationDispatch();
  const { handleSubmit, reset } = methods;
  const queryClient = useQueryClient();
  const router = useRouter();

  const { isPending, mutate } = useHandledMutation({
    mutationFn: (values: FormValues) => api.userControllerInviteUser(values),
    onSuccess: async () => {
      notify({
        variant: 'success',
        title: t('invite.form.notification.title'),
        content: t('invite.form.notification.content'),
      });

      await queryClient.invalidateQueries({
        queryKey: ['all-users', 'pending'],
      });

      reset();
      router.history.back();
    },
  });
  const submitCallback = handleSubmit((values: FormValues) => {
    mutate(values);
  });

  return (
    <PageTemplate title='Invite User'>
      <FormProvider {...methods}>
        <div className='flex h-full w-full flex-column justify-center items-center'>
          <div className='flex items-start w-9/12 gap-4'>
            <div className='grid w-full'>
              <InputField
                name='email'
                placeholder={t('invite.form.email.placeholder')}
                className='w-full'
              />
            </div>
            <Button
              onClick={submitCallback}
              className='w-3/12'
              loading={isPending}
            >
              {t('invite.form.submit')}
            </Button>
          </div>
        </div>
      </FormProvider>
    </PageTemplate>
  );
};
