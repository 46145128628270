import { cva, type VariantProps } from 'class-variance-authority';
import { ButtonHTMLAttributes, forwardRef } from 'react';

import { cn } from '@admin/utils/cn';
import { Loader } from '@admin/features/theme/components/Loader/Loader';
import {
  PolymorphicComponentPropsWithRef,
  PolymorphicRef,
} from '@admin/utils/polymorphic';

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default: 'bg-primary text-primary-foreground hover:bg-primary/90',
        destructive:
          'bg-destructive text-destructive-foreground hover:bg-destructive/90',
        outline:
          'border border-input bg-background hover:bg-accent hover:text-accent-foreground',
        secondary:
          'bg-secondary text-secondary-foreground hover:bg-secondary/80',
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        link: 'text-primary underline-offset-4 hover:underline',
      },
      size: {
        default: 'h-10 px-4 py-2',
        sm: 'h-9 rounded-md px-3',
        lg: 'h-11 rounded-md px-8',
        icon: 'h-10 w-10',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
);

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof buttonVariants> & {
    loading?: boolean;
  };

type PolymorphicButtonProps<
  C extends React.ElementType,
  B extends ButtonProps = ButtonProps,
> = PolymorphicComponentPropsWithRef<C, B>;

type ButtonComponent<P = unknown, B extends ButtonProps = ButtonProps> = <
  C extends React.ElementType = 'button',
>(
  props: PolymorphicButtonProps<C, B> & P
) => React.ReactNode | null;

const Button: ButtonComponent = forwardRef(
  <T extends React.ElementType, R extends PolymorphicRef<T>>(
    {
      className,
      variant,
      size,
      loading,
      disabled: propDisabled,
      children,
      as,
      ...props
    }: PolymorphicButtonProps<T>,
    ref?: R
  ) => {
    const Component = as ?? 'button';
    const disabled = propDisabled ?? loading;
    return (
      <Component
        {...props}
        className={cn(buttonVariants({ variant, size, className }), 'gap-1')}
        ref={ref}
        disabled={disabled}
      >
        {loading ? <Loader height={16} color='white' /> : children}
      </Component>
    );
  }
);

export { Button, buttonVariants };
