import {
  layoutActions,
  LayoutState,
} from '@admin/features/layout/state/layoutSlice';
import {
  ListenerEffectAPI,
  ThunkDispatch,
  UnknownAction,
} from '@reduxjs/toolkit';

export const checkForPendingNotifications = async ({
  dispatch,
  getState,
  delay,
}: ListenerEffectAPI<
  { layout: LayoutState },
  ThunkDispatch<{ layout: LayoutState }, unknown, UnknownAction>
>) => {
  const { layout } = getState();
  const { visibleNotificationsLimit, notificationsBacklog, notifications } =
    layout;
  const availableSize = visibleNotificationsLimit - notifications.length;
  if (availableSize <= 0) {
    return;
  }
  const toDisplay = notificationsBacklog.slice(
    0,
    Math.max(availableSize, notificationsBacklog.length)
  );
  dispatch(layoutActions.showNotifications(toDisplay));
  await Promise.all(
    toDisplay.map(async (n) => {
      await delay(n.timeout);
      dispatch(layoutActions.closeNotification(n));
    })
  );
};
