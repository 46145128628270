import { FC, ReactNode } from 'react';
import { Notification } from '@admin/features/layout/types/Notification';
import {
  Alert,
  AlertDescription,
  AlertTitle,
} from '@admin/features/theme/components/Alert/Alert';
import {
  FaTriangleExclamation,
  FaXmark,
  FaInfo,
  FaCheck,
} from 'react-icons/fa6';

export type SnackbarProps = Pick<Notification, 'title' | 'content' | 'variant'>;

const variants: Record<SnackbarProps['variant'], ReactNode> = {
  error: <FaXmark />,
  info: <FaInfo />,
  success: <FaCheck />,
  warning: <FaTriangleExclamation />,
  unknown: null,
};

export const Snackbar: FC<SnackbarProps> = ({ title, content, variant }) => {
  return (
    <Alert variant={variant} className='shadow-lg bg-white'>
      {variants[variant]}
      <AlertTitle>{title}</AlertTitle>
      <AlertDescription>{content}</AlertDescription>
    </Alert>
  );
};
