import {
  UserHierarchyNodeDto,
  UserInvitationState,
  UserResponseDto,
} from '@admin/features/api/generated';

export const isPendingUser = (
  user: UserResponseDto | UserHierarchyNodeDto
): boolean => user.state === UserInvitationState.PENDING_INVITATION;

export const getIndexByUserID = (
  users: UserHierarchyNodeDto[],
  id: string
): number => {
  return users.findIndex((user) => user.id === id);
};
