import { cn } from '@admin/utils/cn';
import {
  Field,
  Radio,
  RadioGroup as HeadlessRadioGroup,
} from '@headlessui/react';
import React, { ReactElement } from 'react';

type RadioGroupProps = {
  value: string;
  onChange(val: string): void;
  disabled?: boolean;
  className?: string;
  children?: React.ReactNode;
};

type RadioGroupItemProps = {
  value: string;
  disabled?: boolean;
  className?: string;
  children?:
    | React.ReactNode
    | ((values: { disabled: boolean; checked: boolean }) => ReactElement);
};

export const RadioGroup: React.FC<RadioGroupProps> = ({
  value,
  className,
  children,
  disabled,
  onChange,
}) => {
  return (
    <HeadlessRadioGroup
      className={cn('border flex rounded-lg box-border', className)}
      value={value}
      onChange={onChange}
      disabled={disabled}
    >
      {children}
    </HeadlessRadioGroup>
  );
};

export const RadioGroupItem: React.FC<RadioGroupItemProps> = ({
  value,
  disabled,
  children,
  className,
}) => {
  return (
    <Field
      className='group w-full cursor-pointer text-center block select-none'
      disabled={disabled}
    >
      <Radio
        className={cn(
          'data-[checked]:bg-black data-[checked]:data-[disabled]:bg-black/10 data-[disabled]:text-black/70 data-[disabled]:cursor-not-allowed data-[checked]:text-white',
          'group-first:rounded-l-lg group-last:rounded-r-lg group-first:border-l-0',
          'h-full p-1 w-full block truncate group border-l',
          className
        )}
        value={value}
      >
        {children}
      </Radio>
    </Field>
  );
};
