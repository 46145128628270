export const flatten = <T>(
  items: T[],
  getChildren: (item: T) => T[] | null | undefined
): T[] => {
  const stack: T[] = [...items];
  const result: T[] = [];
  while (stack.length > 0) {
    const item = stack.pop()!;
    const children = getChildren(item);
    if ((children?.length ?? 0) > 0) {
      stack.push(...children!);
    }
    result.push(item);
  }
  return result.reverse();
};
