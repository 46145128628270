import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TagResponseDto } from '@admin/features/api/generated';

type BlogState = {
  tags: TagResponseDto[];
};

const initialState: BlogState = {
  tags: [],
};

const editorSlice = createSlice({
  name: 'blogSlice',
  initialState,
  reducers: {
    setTags: (state, action: PayloadAction<TagResponseDto[]>) => {
      state.tags = action.payload;
    },
    appendTag: (state, action: PayloadAction<TagResponseDto>) => {
      state.tags.push(action.payload);
    },
    removeTag: (state, action: PayloadAction<TagResponseDto>) => {
      state.tags = state.tags.filter((tag) => tag.id !== action.payload.id);
    },
  },
});

export const editorActions = editorSlice.actions;

export const editorReducer = editorSlice.reducer;
