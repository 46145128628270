import { PageTemplate } from '@admin/features/layout/components/PageTemplate/PageTemplate';
import { useTranslation } from 'react-i18next';

export const Dashboard = () => {
  const { t } = useTranslation('dashboard');
  return (
    <PageTemplate title={t('dashboard.title')} breadcrumbs={false}>
      <p>{t('dashboard.content')}</p>
    </PageTemplate>
  );
};
