import { createListenerMiddleware } from '@reduxjs/toolkit';
import {
  layoutActions,
  LayoutState,
} from '@admin/features/layout/state/layoutSlice';
import { checkForPendingNotifications } from '@admin/features/layout/utils/checkForPendingNotifications';

export const createNotificationListenerMiddleware = createListenerMiddleware<{
  layout: LayoutState;
}>();

createNotificationListenerMiddleware.startListening({
  actionCreator: layoutActions.createNotification,
  effect: (_, listenerApi) => checkForPendingNotifications(listenerApi),
});

export const closeNotificationListenerMiddleware = createListenerMiddleware<{
  layout: LayoutState;
}>();

closeNotificationListenerMiddleware.startListening({
  actionCreator: layoutActions.closeNotification,
  effect: (_, listenerApi) => checkForPendingNotifications(listenerApi),
});
