import env from '@admin/env';
import { Api } from '@admin/features/api/generated';
import { storage } from '@admin/storage';
import { QueryClient } from '@tanstack/react-query';
import routeNames from '@admin/features/api/routeNames';

const apiClient = new Api({
  baseURL: env.apiUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

/**
 * Interceptor for adding access token from local storage to every request.
 */
apiClient.instance.interceptors.request.use((request) => {
  const auth = storage.read('auth');
  if (auth) {
    request.headers.Authorization = `Bearer ${auth.accessToken}`;
  }
  return request;
});

/**
 * Interceptor for handling expiration of refresh token. It basically checks for response with error code 401, refreshes
 * token and tries the same request again.
 */
apiClient.instance.interceptors.response.use(
  (r) => r,
  async (error) => {
    const originalRequest = error.config;
    if (
      [
        routeNames.authControllerSignIn,
        routeNames.authControllerRefreshTokens,
      ].includes(originalRequest.url)
    ) {
      throw error;
    }
    // Try it 3 times
    if (
      originalRequest.url !== routeNames.authControllerSignIn &&
      error.response.status === 401 &&
      (originalRequest._retryCount || 0) < 2
    ) {
      originalRequest._retryCount = originalRequest._retryCount ?? -1;
      originalRequest._retryCount++;
      try {
        const auth = storage.read('auth');
        if (!auth?.refreshToken) {
          // Refresh token is missing. This means that either someone manipulated the local storage or user is trying
          // to log in with incorrect credentials.
          throw error;
        }
        // Make a request to your auth server to refresh the token.
        const response = await apiClient.api.authControllerRefreshTokens({
          refreshToken: auth.refreshToken,
        });
        const { accessToken, refreshToken: newRefreshToken } = response.data;
        // Store the new access and refresh tokens.
        storage.write('auth', {
          accessToken,
          refreshToken: newRefreshToken,
        });
        // Update the authorization header with the new access token.
        // eslint-disable-next-line require-atomic-updates
        apiClient.instance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
        // Retry the original request with the new access token.
        return apiClient.instance(originalRequest);
      } catch (refreshError) {
        // Handle refresh token errors by clearing stored tokens and redirecting to the login page.
        storage.remove('auth');
        window.location.href = '/';
        throw refreshError;
      }
    }
    // For all other errors, return the error as is
    throw error;
  }
);

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 300_000,
      gcTime: 300_000,
    },
  },
});

export const { api } = apiClient;
