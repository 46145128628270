import { createFileRoute, redirect } from '@tanstack/react-router';
import { Login } from '@admin/features/auth/components/Login/Login';

export const Route = createFileRoute('/')({
  beforeLoad: ({ context }) => {
    const { isAuthenticated } = context.auth;
    if (isAuthenticated) {
      return redirect({ to: '/dashboard' });
    }
  },
  component: Login,
});
