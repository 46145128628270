import type { QueryClient } from '@tanstack/react-query';

export const invalidateFolders = async (
  queryClient: QueryClient,
  paths: string[],
  folderIds: (string | undefined | null)[]
): Promise<void> => {
  const rootFolderFound = paths.some((path) => path === '/');
  if (rootFolderFound) {
    await queryClient.invalidateQueries({
      queryKey: ['all-folders'],
    });
  }

  for (const folderId of folderIds) {
    if (!folderId) {
      return;
    }
    await queryClient.invalidateQueries({
      queryKey: ['single-folder', folderId],
    });
  }

  await queryClient.invalidateQueries({
    queryKey: ['file-explorer-root'],
  });
  await queryClient.invalidateQueries({
    queryKey: ['root-folder-data'],
  });
  await queryClient.invalidateQueries({
    queryKey: ['root-folder-info'],
  });
};
