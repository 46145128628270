export default {
  userControllerSetSupervisor: '/api/users/{id}/supervisor',
  userControllerGetUserHierarchy: '/api/users/hierarchy',
  userControllerFindAll: '/api/users',
  userControllerGetCurrent: '/api/users/me',
  userControllerSetUserName: '/api/users/me/name',
  userControllerFindOne: '/api/users/{id}',
  userControllerDeleteUser: '/api/users/{id}',
  userControllerInviteUser: '/api/users/invitations',
  userControllerInvitationExists: '/api/users/invitations/{token}',
  userControllerAcceptInvitation: '/api/users/invitations/{token}/accept',
  userControllerFindPermissions: '/api/users/{id}/permissions',
  userControllerUpsertPermissions: '/api/users/{id}/permissions',
  userControllerSortUsers: '/api/users/sort',
  authControllerSignIn: '/api/auth/login',
  authControllerRefreshTokens: '/api/auth/refresh',
  authControllerLogout: '/api/auth/logout',
  authControllerForgotPassword: '/api/auth/forgot-password',
  authControllerResetPassword: '/api/auth/reset-password',
  blogControllerCreateBlog: '/api/blogs',
  blogControllerUpdateBlog: '/api/blogs/{id}',
  blogControllerDeleteBlog: '/api/blogs/{id}',
  blogControllerGetBlog: '/api/blogs/{id}',
  blogControllerGetSubmittedBlogs: '/api/blogs/approval',
  blogControllerGetApprovedBlogs: '/api/blogs/approved',
  blogControllerGetBlogGroupsWithBlogs: '/api/blogs/groups',
  blogControllerCheckIfSlugExists: '/api/blogs/{slug}/{language}',
  blogControllerApproveBlog: '/api/blogs/{id}/approve',
  blogControllerRejectBlog: '/api/blogs/{id}/reject',
  blogControllerGetBlogsByGroup: '/api/blogs/group/{groupId}',
  blogControllerFindAllComments: '/api/blogs/{id}/comments',
  blogGroupControllerCreate: '/api/blog-groups',
  blogGroupControllerFindAll: '/api/blog-groups',
  blogGroupControllerUpdate: '/api/blog-groups/{id}',
  blogGroupControllerRemove: '/api/blog-groups/{id}',
  blogPublicControllerGetBlogsPreview: '/api/public/blogs/preview',
  blogPublicControllerGetBlog: '/api/public/blogs/{slug}',
  blogPublicControllerGetBlogs: '/api/public/blogs',
  blogFilesControllerGetFileBlogs: '/api/files/{id}/blogs',
  webhookControllerCreate: '/api/webhooks',
  webhookControllerFindAll: '/api/webhooks',
  webhookControllerFindOne: '/api/webhooks/{id}',
  webhookControllerUpdate: '/api/webhooks/{id}',
  webhookControllerRemove: '/api/webhooks/{id}',
  fileControllerCreateNewFile: '/api/files',
  fileControllerUpdateFile: '/api/files/{id}',
  fileControllerDeleteFile: '/api/files/{id}',
  fileControllerGetFile: '/api/files/{id}',
  fileControllerUpsertFileTranslation: '/api/files/{id}/translation/{language}',
  fileControllerDeleteFileTranslation: '/api/files/{id}/translation/{language}',
  directoryControllerScanRootDir: '/api/directories',
  directoryControllerCreateDirectory: '/api/directories',
  directoryControllerScanDir: '/api/directories/{id}',
  directoryControllerUpdateDirectory: '/api/directories/{id}',
  directoryControllerDeleteDirectory: '/api/directories/{id}',
  tagControllerCreate: '/api/tags',
  tagControllerFindAll: '/api/tags',
  tagControllerFindAllWithGroups: '/api/tags/groups',
  tagControllerFindOne: '/api/tags/{id}',
  tagControllerUpdate: '/api/tags/{id}',
  tagControllerRemove: '/api/tags/{id}',
  tagGroupControllerCreate: '/api/tag-groups',
  tagGroupControllerFindAll: '/api/tag-groups',
  tagGroupControllerFindOne: '/api/tag-groups/{id}',
  tagGroupControllerUpdate: '/api/tag-groups/{id}',
  tagGroupControllerRemove: '/api/tag-groups/{id}',
  tagPublicControllerGetAll: '/api/public/tags',
  commentControllerFindOne: '/api/comments/{id}',
  commentControllerRemove: '/api/comments/{id}',
  commentAuthorControllerListAllAuthors: '/api/comments/authors',
  commentAuthorControllerUnbanAuthor: '/api/comments/authors/{id}/unban',
  commentAuthorControllerBanAuthor: '/api/comments/authors/{id}/ban',
  contactControllerContactSales: '/api/contacts/sales',
  newsletterControllerCreateContact: '/api/newsletter/contacts',
  newsletterControllerListContacts: '/api/newsletter/contacts',
  configControllerGetConfig: '/api/config',
};
