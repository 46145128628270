import {
  TagGroupWithTagsDto,
  TagResponseDto,
} from '@admin/features/api/generated';
import { Tag } from '@admin/features/blogs/components/Tag/Tag';
import { DroppableTagsArea } from '@admin/features/theme/components/DroppableTagsArea/DroppableTagsArea';
import React from 'react';
import { CreateTagModal } from '../CreateTagModal/CreateTagModal';
import { RemoveTagModal } from '../RemoveTagModal/RemoveTagModal';
import { toggleValue } from '@admin/utils';
import { TAG_IDS_NAME } from '../TagsSearchModal/TagsSearchModal';
import { useFormContext } from 'react-hook-form';
import { useModal } from '@admin/features/layout/modals/useModal';

type TagsGalleryGroupProps = {
  groups: TagGroupWithTagsDto[];
};

export const TagsGalleryGroups: React.FC<TagsGalleryGroupProps> = ({
  groups,
}) => {
  const { watch, setValue } = useFormContext();

  const selectedTagIds: string[] = watch(TAG_IDS_NAME);

  const { openModal } = useModal();

  const updateTagHandler = (tag: TagResponseDto): void => {
    openModal(<CreateTagModal tag={tag} />);
  };

  const removeTagHandler = (tag: TagResponseDto): void => {
    openModal(<RemoveTagModal tag={tag} />);
  };

  const toggleTagHandler = (tag: TagResponseDto): void => {
    setValue(TAG_IDS_NAME, toggleValue(selectedTagIds, tag.id));
  };

  return (
    <>
      {groups.map((group) => (
        <div key={group.id} className='mt-1'>
          <span>{group.name}</span>
          <DroppableTagsArea id={group.id}>
            <Tag.Group>
              {group.tags.map((tag) => (
                <Tag
                  draggable
                  key={tag.id}
                  {...tag}
                  selected={selectedTagIds.includes(tag.id)}
                  onUpdate={updateTagHandler}
                  onRemove={removeTagHandler}
                  onSelect={toggleTagHandler}
                />
              ))}
            </Tag.Group>
          </DroppableTagsArea>
        </div>
      ))}
    </>
  );
};
