import * as yup from 'yup';
import {
  BlogLevelAccessRole,
  UserHierarchyNodeDto,
  UserLevelAccessRole,
  UserResponseDto,
} from '@admin/features/api/generated';
import { InferType } from 'yup';
import { useForm } from '@admin/hooks/useForm';
import { useTranslation } from 'react-i18next';
import { useHandledMutation } from '@admin/hooks/useHandledMutation';
import { api } from '@admin/features/api';
import React, { useEffect } from 'react';
import { useNotificationDispatch } from '@admin/features/layout/hooks/useNotificationDispatch';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Loader } from '@admin/features/theme/components/Loader/Loader';
import { FormProvider } from 'react-hook-form';
import { Select } from '@admin/features/theme/components/Select/Select';
import {
  RadioGroup,
  RadioGroupItem,
} from '@admin/features/theme/components/RadioGroup/RadioGroup';
import { Modal } from '@admin/features/theme/components/Modals/Modal';

const schema = yup.object({
  userLevelAccessRole: yup
    .string()
    .required()
    .oneOf(Object.values(UserLevelAccessRole))
    .default(UserLevelAccessRole.UserEditor)
    .ensure(),
  blogLevelAccess: yup
    .array(
      yup
        .object({
          blogLevelAccessRole: yup
            .string()
            .required()
            .oneOf(Object.values(BlogLevelAccessRole)),
          blogGroupId: yup.string().required(),
        })
        .required()
    )
    .default([]),
});
type FormValues = InferType<typeof schema>;

export type UserPermissionsModalProps = {
  onClose(): void;
  isOpen: boolean;
  user: UserHierarchyNodeDto | UserResponseDto;
};

export const UserPermissionsModal: React.FC<UserPermissionsModalProps> = ({
  onClose,
  user,
  isOpen,
}) => {
  const { t } = useTranslation('users');
  const methods = useForm({
    schema,
    defaultValues: schema.cast({}),
  });
  const notificationDispatch = useNotificationDispatch();
  const queryClient = useQueryClient();

  const blogLevelAccess = methods.watch('blogLevelAccess');
  const userLevelAccessRole = methods.watch('userLevelAccessRole');

  const {
    data: userPermissions,
    isLoading,
    isSuccess: isUserPermissionSuccess,
    isError: isUserPermissionError,
  } = useQuery({
    queryKey: ['userPermissions', user.id],
    queryFn: () => api.userControllerFindPermissions(user.id),
  });

  const { mutate: updatePermissions, isPending: isSavingUserPermissions } =
    useHandledMutation({
      mutationKey: ['userControllerUpdatePerms', user.id],
      mutationFn: ({ id, values }: { id: string; values: FormValues }) =>
        api.userControllerUpsertPermissions(id, values),
      onSuccess: async () => {
        notificationDispatch({
          content: t('list.modal.manage.success.alert', {
            user: user.fullName,
          }),
          variant: 'success',
        });
        await queryClient.invalidateQueries({
          queryKey: ['userPermissions', user.id],
        });
      },
    });

  useEffect(() => {
    if (!isUserPermissionSuccess) {
      return;
    }
    methods.setValue(
      'userLevelAccessRole',
      userPermissions.data.userLevelAccessRole
    );
  }, [isUserPermissionSuccess]);

  const handleManageModalSave = (): void => {
    const values = methods.getValues();
    const filteredValues = {
      ...values,
      blogLevelAccess: userPermissions?.data.blogLevelAccess.map(
        (item, index) =>
          values.blogLevelAccess[index] ?? {
            blogLevelAccessRole: item.blogLevelAccessRole,
            blogGroupId: item.blogGroup.id,
          }
      ),
    };
    updatePermissions({
      id: user.id,
      values: filteredValues as FormValues,
    });
    onClose();
  };

  const handleSelect = (e: React.MouseEvent<HTMLSelectElement>): void => {
    const { value } = e.currentTarget;
    methods.setValue('userLevelAccessRole', value as UserLevelAccessRole);
  };

  const handleBlogPermissionChange = (
    role: BlogLevelAccessRole,
    index: number,
    groupId: string
  ): void => {
    methods.setValue(`blogLevelAccess.${index}.blogGroupId`, groupId);
    methods.setValue(`blogLevelAccess.${index}.blogLevelAccessRole`, role);
  };

  const getBlogGroupSelectValue = (index: number): BlogLevelAccessRole => {
    return (
      blogLevelAccess[index]?.blogLevelAccessRole ??
      userPermissions?.data.blogLevelAccess[index].blogLevelAccessRole
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={handleManageModalSave}
      size={isUserPermissionError || isLoading ? 'small' : 'medium'}
      title={t('list.modal.manage.user.title', { user: user.fullName })}
      loading={isSavingUserPermissions}
    >
      {isLoading && <Loader />}
      {isUserPermissionError && (
        <p className='text-red-600'>{t('list.modal.manage.error.msg')}</p>
      )}
      {isUserPermissionSuccess && (
        <FormProvider {...methods}>
          <div className='flex flex-col gap-4'>
            <Select
              name='role'
              label={t('list.modal.manage.user.role.label')}
              className='mb-8'
              defaultValue={userLevelAccessRole}
              onChange={handleSelect}
            >
              {Object.values(UserLevelAccessRole).map((role, index) => (
                <option
                  key={role}
                  value={role}
                  selected={userLevelAccessRole === role}
                >
                  {t(`list.modal.manage.user.level.${index}`)}
                </option>
              ))}
            </Select>
            <h1>{t('list.modal.manage.group.permissions.label')}</h1>
            {userPermissions.data.blogLevelAccess.map((role, index) => (
              <div
                key={role.blogGroup.id}
                className='flex justify-between items-center'
              >
                <h1 className='w-1/4 truncate'>{role.blogGroup.name}</h1>
                <RadioGroup
                  value={getBlogGroupSelectValue(index)}
                  onChange={(value: BlogLevelAccessRole) => {
                    handleBlogPermissionChange(value, index, role.blogGroup.id);
                  }}
                  className='w-3/4'
                >
                  {Object.values(BlogLevelAccessRole).map(
                    (blogAccessRole, index) => (
                      <RadioGroupItem
                        key={role.blogGroup.id + blogAccessRole}
                        value={blogAccessRole}
                      >
                        {t(`list.modal.manage.blog.level.permission.${index}`)}
                      </RadioGroupItem>
                    )
                  )}
                </RadioGroup>
              </div>
            ))}
          </div>
        </FormProvider>
      )}
    </Modal>
  );
};
