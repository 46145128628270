import { Layout } from '@admin/features/layout/components/Layout/Layout';
import { createFileRoute, Outlet, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/_layout')({
  beforeLoad: ({ context }) => {
    const { isAuthenticated } = context.auth;
    if (!isAuthenticated) {
      return redirect({ to: '/' });
    }
  },
  component: () => (
    <Layout>
      <Outlet />
    </Layout>
  ),
});
