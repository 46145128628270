import React from 'react';
import { Breadcrumbs } from '@admin/features/layout/components/Navigation/Breadcrumbs';
import { cn } from '@admin/utils/cn';

// We are using pathname for breadcrumbs.
// This limits for example assets to have correct and good looking breadcrumbs
// So there is option to inject custom ones
// This should be refactored in terms of default breadcrumbs or use of normal routes

type PageTemplateProps = {
  title: string;
  breadcrumbs?: React.ReactNode | true | false;
  children?: React.ReactNode;
  className?: string;
};

export const PageTemplate: React.FC<PageTemplateProps> = ({
  title,
  children,
  breadcrumbs = true,
  className,
}) => {
  return (
    <div
      className={cn(
        className,
        'content text-black pr-8 pl-8 pb-8 space-y-4 w-full h-[90vh] overflow-y-scroll'
      )}
    >
      {typeof breadcrumbs !== 'boolean'
        ? breadcrumbs
        : breadcrumbs && <Breadcrumbs />}
      <h1
        className={cn('text-4xl font-semibold', {
          'mt-8': !breadcrumbs,
        })}
      >
        {title}
      </h1>
      {children}
    </div>
  );
};
