import { FileResponseDto } from '@admin/features/api/generated';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from '@admin/features/theme/components/Breadcrumb/Breadcrumb';
import { Link } from '@tanstack/react-router';
import React from 'react';
import { useTranslation } from 'react-i18next';

type CreateFolderBreadcrumbsProps = {
  folderInfo: FileResponseDto;
};

export const CreateFolderBreadcrumbs: React.FC<
  CreateFolderBreadcrumbsProps
> = ({ folderInfo }) => {
  const { t } = useTranslation('assets');

  return (
    <Breadcrumb>
      <BreadcrumbList className='select-none'>
        <BreadcrumbItem>
          <BreadcrumbLink>
            <Link to='/assets'>{t('folders.breadcrumb.root')}</Link>
          </BreadcrumbLink>
        </BreadcrumbItem>
        {folderInfo.parentDirectoryId && <BreadcrumbSeparator />}
        <BreadcrumbItem>
          <BreadcrumbLink>
            <Link to={`/assets/folder/${folderInfo.id}`}>
              {folderInfo.name}
            </Link>
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbSeparator />
        <BreadcrumbItem className='font-bold'>Create folder</BreadcrumbItem>
      </BreadcrumbList>
    </Breadcrumb>
  );
};
