import { cn } from '@admin/utils/cn';
import React from 'react';

type SeparatorProps = {
  orientation?: 'horizontal' | 'vertical';
  className?: string;
};

export const Separator: React.FC<SeparatorProps> = ({
  orientation = 'horizontal',
  className,
}) => {
  return (
    <div
      className={cn(
        'shrink-0 bg-border',
        orientation === 'horizontal' ? 'h-[1px] w-full' : 'h-full w-[1px]',
        className
      )}
    ></div>
  );
};
