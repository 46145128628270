import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react';
import { Button } from '@admin/features/theme/components/Button/Button';
import { CSSProperties, FC, PropsWithChildren, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

type ModalProps = {
  isOpen: boolean;
  title: string;
  description?: ReactNode;
  closeOnOutsideClick?: boolean;
  onPrevious?: VoidFunction;
  onClose: VoidFunction;
  onConfirm?: VoidFunction;
  loading?: boolean;
  disabled?: boolean;
  size?: 'large' | 'medium' | 'small';
};

export const Modal: FC<PropsWithChildren<ModalProps>> = ({
  isOpen,
  title,
  description,
  children,
  onClose,
  onConfirm,
  onPrevious,
  closeOnOutsideClick = false,
  loading,
  disabled,
  size = 'small',
}) => {
  const { t } = useTranslation('common');

  const sizeStyle: CSSProperties = {
    width: size === 'large' ? '75vw' : size === 'medium' ? '65vw' : '35vw',
    minWidth:
      size === 'large' ? '700px' : size === 'medium' ? '500px' : '250px',
    height: size === 'large' ? '60vh' : size === 'medium' ? '55vh' : 'auto',
    minHeight:
      size === 'large' ? '500px' : size === 'medium' ? '400px' : 'auto',
    maxHeight: '60vh',
  };

  return (
    <Dialog
      open={isOpen}
      onClose={closeOnOutsideClick ? onClose : (): string => ''}
      className='relative z-50'
    >
      <div className='fixed inset-0 flex w-screen items-center justify-center p-4 bg-black/20'>
        <DialogPanel className='max-w-screen max-h-screen space-y-6 border bg-white p-4 rounded-lg'>
          <div className='space-y-2'>
            <DialogTitle className='font-bold text-2xl capitalize'>
              {title}
            </DialogTitle>
            {description && (
              <p className='text-sm text-gray-500'>{description}</p>
            )}
          </div>
          <div className='overflow-auto p-1' style={sizeStyle}>
            {children}
          </div>
          <div className='flex gap-4 justify-end'>
            {onPrevious ? (
              <Button variant='secondary' onClick={onPrevious}>
                {t('dialog.buttons.previous')}
              </Button>
            ) : (
              <Button variant='secondary' onClick={onClose}>
                {t('dialog.buttons.cancel')}
              </Button>
            )}
            {onConfirm && (
              <Button loading={loading} disabled={disabled} onClick={onConfirm}>
                {t('dialog.buttons.confirm')}
              </Button>
            )}
          </div>
        </DialogPanel>
      </div>
    </Dialog>
  );
};
