export const splitName = (fullFileName: string): [string, string] | null => {
  const lastDotIndex = fullFileName.lastIndexOf('.');

  if (lastDotIndex === -1 || lastDotIndex === 0) {
    return null;
  }

  const name = fullFileName.slice(0, lastDotIndex);
  const extension = fullFileName.slice(lastDotIndex);

  return [name, extension];
};
