import { api, queryClient } from '@admin/features/api';
import { FileResponseDto } from '@admin/features/api/generated';
import { SingleFolder } from '@admin/features/assets/components/SingleFolder/SingleFolder';
import {
  createFileRoute,
  redirect,
  useLoaderData,
  useParams,
} from '@tanstack/react-router';

const Component: React.FC = () => {
  const params = useParams({ from: '/_layout/assets/folder/$folderId' });
  const loaderData = useLoaderData({
    from: '/_layout/assets/folder/$folderId',
  });
  const correctedLoaderData = loaderData as unknown as FileResponseDto;
  return <SingleFolder folderInfo={correctedLoaderData} {...params} />;
};

export const Route = createFileRoute('/_layout/assets/folder/$folderId')({
  loader: async ({ params: { folderId } }) => {
    const data = await queryClient.fetchQuery({
      queryKey: ['single-folder-info', folderId],
      queryFn: () => api.fileControllerGetFile(folderId),
    });
    return data.data;
  },
  beforeLoad: ({ context }) => {
    const { isAuthenticated } = context.auth;
    if (!isAuthenticated) {
      return redirect({ to: '/' });
    }
  },
  component: Component,
});
