import { useCallback, useState } from 'react';
import {
  QueryFunction,
  QueryKey,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

export default function useLazyQuery<TData, TError>(
  key: QueryKey,
  fetchFn: QueryFunction<TData>,
  options?: UseQueryOptions<TData, TError, unknown>
): [() => void, UseQueryResult<unknown, unknown>] {
  const [enabled, setEnabled] = useState(false);

  const query = useQuery<TData, TError, unknown>({
    ...(options ?? {}),
    queryKey: key,
    queryFn: fetchFn,
    enabled,
  });

  const fetch = useCallback(() => {
    if (!enabled) {
      setEnabled(true);
    }
  }, [enabled]);

  return [fetch, query];
}
