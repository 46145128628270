import { TagResponseDto } from '@admin/features/api/generated';
import { DropdownOption } from '@admin/features/theme/components/SelectLanguage/SelectLanguage';
import { getTranslatedContent } from '@admin/hooks/useTranslatedContent';
import { useMemo } from 'react';

export const useGetAutofillTagsData = (
  tags: TagResponseDto[]
): DropdownOption[] => {
  return useMemo(
    () =>
      tags.map((tag) => {
        const translation = getTranslatedContent(tag.translations);
        return { id: tag.id, name: translation!.name };
      }),
    [tags]
  );
};
