import { Loader } from '@admin/features/theme/components/Loader/Loader';
import { EmptyListAlert } from '@admin/features/layout/components/PageTemplate/EmptyListAlert';
import { BlogPostListItemWrapper } from '@admin/features/blogs/components/BlogPostListItem/BlogPostListItemWrapper';
import { BlogPostListItem } from '@admin/features/blogs/components/BlogPostListItem/BlogPostListItem';
import { Pagination } from '@admin/features/theme/components/Pagination/Pagination';
import { BlogResponseDto } from '@admin/features/api/generated';
import { useBlogsTranslation } from '@admin/features/blogs/locales/useBlogsTranslation';

export type PaginatedBlogListProps = {
  isFetching: boolean;
  totalItems: number;
  blogs?: BlogResponseDto[];
  title: string;
  rootClassName?: string;

  page: number;
  limit: number;
  onPageChange(page: number): void;

  allowToApprove?: boolean;
  disableGroupColumn?: boolean;
};

export const PaginatedBlogList = ({
  isFetching,
  totalItems,
  blogs,
  title,
  rootClassName,

  page,
  limit,
  onPageChange,

  allowToApprove,
  disableGroupColumn,
}: PaginatedBlogListProps) => {
  const { t } = useBlogsTranslation();
  return (
    <div className={rootClassName}>
      <span className='text-xl flex flex-row gap-x-1 items-center font-bold'>
        {title}
        <span className='text-sm'>({totalItems})</span>
      </span>
      <div className='mt-6 flex flex-col gap-y-2'>
        {isFetching || !blogs ? (
          <Loader />
        ) : blogs.length === 0 ? (
          <EmptyListAlert />
        ) : (
          <>
            <BlogPostListItemWrapper disableGroupColumn={disableGroupColumn}>
              <div className='grid grid-cols-subgrid col-start-1 -col-end-1'>
                <div className='px-3 font-bold'>{t('list.header.name')}</div>
                <div className='px-3 font-bold'>{t('list.header.author')}</div>
                {!disableGroupColumn && (
                  <div className='px-3 font-bold'>
                    {t('list.header.blogGroup')}
                  </div>
                )}
                <div className='px-3 font-bold text-right'>
                  {t('list.header.actions')}
                </div>
              </div>
              {blogs.map((post) => (
                <BlogPostListItem
                  {...post}
                  blogGroup={post.blogGroup}
                  key={post.id}
                  allowToApprove={allowToApprove}
                  disableGroupColumn={disableGroupColumn}
                />
              ))}
            </BlogPostListItemWrapper>
            <Pagination
              page={page}
              totalItems={totalItems}
              limit={limit}
              controlled
              onPageChange={onPageChange}
            />
          </>
        )}
      </div>
    </div>
  );
};
