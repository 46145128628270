import { Folders } from '@admin/features/assets/components/Folders/Folders';
import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/_layout/assets/')({
  beforeLoad: ({ context }) => {
    const { isAuthenticated } = context.auth;
    if (!isAuthenticated) {
      return redirect({ to: '/' });
    }
  },
  component: Folders,
});
