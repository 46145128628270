import { FileResponseDto } from '@admin/features/api/generated';
import { cn } from '@admin/utils/cn';
import { useTranslation } from 'react-i18next';

type DirectoryProps = FileResponseDto &
  React.ComponentProps<'div'> & {
    selected: boolean;
  };

export const Directory: React.FC<DirectoryProps> = ({
  id,
  name,
  selected,
  ...props
}) => {
  const { t } = useTranslation('assets');

  return (
    <div
      {...props}
      key={id}
      className={cn(
        'col-span-1 rounded-lg w-full p-4 py-8 border items-center justify-center bg-white select-none hover:bg-slate-100 hover:border-blue-600 cursor-pointer',
        selected && 'border-blue-600 bg-slate-100'
      )}
    >
      <div className='text-xs text-gray-400 uppercase'>
        {t('file.explorer.folder.type')}
      </div>
      <span className='font-semibold'>{name}</span>
    </div>
  );
};
