import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CreateNotificationPayload,
  Notification,
} from '@admin/features/layout/types/Notification';
import update from 'immutability-helper';
import { createNotification } from '@admin/features/layout/utils/createNotification';
import { DEFAULT_MAX_VISIBLE_NOTIFICATIONS } from '@admin/features/layout/defs';

export type LayoutState = {
  notificationsBacklog: Notification[];
  notifications: Notification[];
  visibleNotificationsLimit: number;
};

const initialState: LayoutState = {
  visibleNotificationsLimit: DEFAULT_MAX_VISIBLE_NOTIFICATIONS,
  notifications: [],
  notificationsBacklog: [],
};

const layoutSlice = createSlice({
  name: 'layoutSlice',
  initialState,
  reducers: {
    createNotification: (
      state,
      action: PayloadAction<CreateNotificationPayload>
    ) =>
      update(state, {
        notificationsBacklog: {
          $push: [createNotification(action.payload)],
        },
      }),
    showNotifications: (state, action: PayloadAction<Notification[]>) => {
      const ids = action.payload.map((n) => n.id);
      return update(state, {
        notificationsBacklog: {
          $apply: (items: Notification[]) =>
            items.filter((item) => !ids.includes(item.id)),
        },
        notifications: {
          $push: action.payload,
        },
      });
    },
    closeNotification: (
      state,
      action: PayloadAction<Pick<Notification, 'id'>>
    ) =>
      update(state, {
        notifications: {
          $apply: (items: Notification[]) =>
            items.filter((i) => i.id !== action.payload.id),
        },
      }),
  },
});

export const layoutActions = layoutSlice.actions;

export const layoutReducer = layoutSlice.reducer;
