/* eslint-disable */
import { api, queryClient } from '@admin/features/api'
import { FileResponseDto } from '@admin/features/api/generated'
import { UploadFileBreadcrumbs } from '@admin/features/assets/components/Breadcrumbs/UploadFileBreadcrumbs'
import { UploadFile } from '@admin/features/assets/components/UploadFile/UploadFile'
import { PageTemplate } from '@admin/features/layout/components/PageTemplate/PageTemplate'
import {
  createFileRoute,
  redirect,
  useLoaderData,
  useParams,
} from '@tanstack/react-router'
import { useTranslation } from 'react-i18next'

const Component: React.FC = () => {
  const { t } = useTranslation('assets')
  const params = useParams({
    from: '/_layout/assets/folder_/$folderId_/file_/$fileId/upload',
  })
  const loaderData = useLoaderData({
    from: '/_layout/assets/folder_/$folderId_/file_/$fileId/upload',
  })
  const [folderInfo, fileData, folderData] = loaderData
  return (
    <PageTemplate title={t('edit.file.title')} breadcrumbs={<UploadFileBreadcrumbs folderInfo={folderInfo} update={true} />}>
      <UploadFile
        {...params}
        folderData={folderData}
        existingFile={fileData}
        path={fileData.path}
        directoryId={params.folderId}
      />
    </PageTemplate>
  )
}

export const Route = createFileRoute(
  '/_layout/assets/folder_/$folderId_/file_/$fileId/upload',
)({
  loader: async ({
    params: { folderId, fileId },
  }): Promise<[FileResponseDto, FileResponseDto, FileResponseDto[]]> => {

    let folderInfo: FileResponseDto;
    let folderData: FileResponseDto[];

    const data = await queryClient.fetchQuery({
      queryKey: ['single-file-info', fileId],
      queryFn: () => api.fileControllerGetFile(fileId),
    })

    if (folderId === 'root') {
      const data = await queryClient.fetchQuery({
        queryKey: ['root-folder-data'],
        queryFn: () => api.directoryControllerScanRootDir(),
      })
      folderData = data.data
      folderInfo = {
        path: '/',
        name: '',
        fullPath: '/',
      } as FileResponseDto
    } else {
      const data = await queryClient.fetchQuery({
        queryKey: ['single-folder-data'],
        queryFn: () => api.directoryControllerScanDir(folderId),
      })
      const folderInfoResponse = await queryClient.fetchQuery({
        queryKey: ['single-folder-info', folderId],
        queryFn: () => api.fileControllerGetFile(folderId),
      })
      folderData = data.data
      folderInfo = folderInfoResponse.data;
    }

    return [folderInfo, data.data, folderData]
  },
  beforeLoad: ({ context }) => {
    const { isAuthenticated } = context.auth
    if (!isAuthenticated) {
      return redirect({ to: '/' })
    }
  },
  component: Component,
})
