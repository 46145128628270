import React from 'react';
import { Modal } from '@admin/features/theme/components/Modals/Modal';
import { FormProvider } from 'react-hook-form';
import { InputField } from '@admin/features/form/components/InputField';
import * as yup from 'yup';
import { useForm } from '@admin/hooks/useForm';
import { useMutation } from '@tanstack/react-query';
import { api } from '@admin/features/api';
import { useNotificationDispatch } from '@admin/features/layout/hooks/useNotificationDispatch';
import { useModal } from '@admin/features/layout/modals/useModal';
import { useBlogsTranslation } from '@admin/features/blogs/locales/useBlogsTranslation';

const schema = yup.object({
  name: yup.string().required(),
});
type FormValues = yup.InferType<typeof schema>;

export const CreateTagGroupModal: React.FC = () => {
  const { t } = useBlogsTranslation();
  const { closeModal, openPreviousModal } = useModal();
  const createNotification = useNotificationDispatch();

  const methods = useForm({
    schema,
    defaultValues: schema.cast({
      name: '',
    }),
  });

  const { handleSubmit } = methods;
  const { mutate, isPending } = useMutation({
    mutationFn: (values: FormValues) => api.tagGroupControllerCreate(values),
    onSuccess: () => {
      createNotification({
        variant: 'success',
        content: t('editor.modals.create-group.success'),
      });
      openPreviousModal();
    },
    onError: () => {
      createNotification({
        variant: 'error',
        content: t('editor.modals.create-group.error'),
      });
    },
  });
  const submitCallback = handleSubmit((values: FormValues) => {
    mutate(values);
  });

  return (
    <Modal
      isOpen
      title={t('editor.modals.create-tag-group.title')}
      onClose={closeModal}
      onConfirm={submitCallback}
      onPrevious={openPreviousModal}
      closeOnOutsideClick
      loading={isPending}
    >
      <FormProvider {...methods}>
        <div className='flex h-full w-full flex-column justify-center items-center'>
          <InputField
            name='name'
            className='w-full'
            label={t('editor.modals.create-tag-group.label')}
          />
        </div>
      </FormProvider>
    </Modal>
  );
};
