import { cn } from '@admin/utils/cn';
import React from 'react';
import Dropzone, { Accept, DropEvent, FileRejection } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { FaFileArrowDown } from 'react-icons/fa6';

type UploadDropzoneProps = {
  onDrop<T extends File>(
    acceptedFiles: T[],
    declinedFiles: FileRejection[],
    e: DropEvent
  ): void;
  className?: string;
  singleFile?: boolean;
  accept?: Accept;
  maxFiles?: number;
  minSizeInMB?: number;
  maxSizeInMB?: number;
  subtitle?: string;
  rootClassName?: string;
};

export const UploadDropzone: React.FC<UploadDropzoneProps> = ({
  onDrop,
  accept,
  className,
  singleFile = false,
  maxFiles = 100,
  minSizeInMB = 0,
  maxSizeInMB = 1024,
  subtitle,
  rootClassName,
}) => {
  const { t } = useTranslation('common');
  return (
    <Dropzone
      onDrop={onDrop}
      accept={accept}
      minSize={minSizeInMB * 1024 ** 2}
      maxSize={maxSizeInMB * 1024 ** 2}
      maxFiles={maxFiles}
      multiple={!singleFile}
    >
      {({ getRootProps, getInputProps, isDragActive }) => {
        return (
          <div {...getRootProps()} className={rootClassName}>
            <div
              className={cn(
                'w-full transition-all border hover:border-blue-600 focus:border-blue-600 flex flex-col space-y-4 items-center justify-center p-4 py-10 rounded-lg cursor-pointer',
                className
              )}
            >
              <FaFileArrowDown className='size-12 text-gray-800' />
              <input {...getInputProps()} />
              {isDragActive ? (
                <span className='truncate'>{t('upload.active.drop')}</span>
              ) : subtitle ? (
                <span className='truncate'>{subtitle}</span>
              ) : (
                <span className='truncate'>{t('upload.cto.drop')}</span>
              )}
            </div>
          </div>
        );
      }}
    </Dropzone>
  );
};
