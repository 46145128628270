import React from 'react';
import { Provider } from 'react-redux';

import AppNavigator from '@admin/AppNavigator';
import { store } from '@admin/store/store';
import { SnackbarQueue } from '@admin/features/layout/components/SnackbarQueue/SnackbarQueue';

const withProviders = (Component: React.FC): React.FC => {
  return () => (
    <Provider store={store}>
      <Component />
      <SnackbarQueue />
    </Provider>
  );
};

const App: React.FC = () => {
  return <AppNavigator />;
};

export default withProviders(App);
