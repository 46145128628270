import React, { useState } from 'react';
import {
  Combobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from '@headlessui/react';
import { DropdownOption } from '@admin/features/theme/components/SelectLanguage/SelectLanguage';
import { useFormContext } from 'react-hook-form';
import { addIfMissing } from '@admin/utils';
import { FormLabel } from '../Form/Form';

type SearchableDropdownProps = {
  autofillData: DropdownOption[];
  name: string;
  label?: string;
  placeholder?: string;
  defaultSelectedOption?: DropdownOption | null;
};

export const SearchField: React.FC<SearchableDropdownProps> = ({
  autofillData,
  name,
  label,
  placeholder,
}) => {
  const [selectedOption, setSelectedOption] = useState<DropdownOption | null>(
    null
  );
  const [query, setQuery] = useState('');
  const { setValue, watch } = useFormContext();

  const items = watch(name);

  const handleSelectionChange = (option: DropdownOption | null): void => {
    setSelectedOption(option);

    if (!option) {
      return;
    }

    setValue(name, addIfMissing(items, option.id));
    setQuery(option.name);
  };

  const filteredData =
    query === ''
      ? autofillData
      : autofillData.filter((item) =>
          item.name.toLowerCase().includes(query.toLowerCase())
        );

  return (
    <div className='relative pt-4 pb-4'>
      {label && <FormLabel>{label}</FormLabel>}
      <Combobox value={selectedOption} onChange={handleSelectionChange}>
        <ComboboxInput
          placeholder={placeholder}
          onChange={(event) => {
            setQuery(event.target.value);
          }}
          className='flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2'
        />
        <ComboboxOptions className='absolute z-10 mt-1 max-h-48 w-full overflow-y-auto rounded-md bg-white shadow-lg dark:bg-gray-700'>
          {filteredData.length > 0 ? (
            filteredData.map((item) => (
              <ComboboxOption
                key={item.id}
                value={item}
                className='flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600 cursor-pointer'
              >
                {item.name}
              </ComboboxOption>
            ))
          ) : (
            <div className='flex items-center p-2 text-gray-500 dark:text-gray-300'>
              No data found
            </div>
          )}
        </ComboboxOptions>
      </Combobox>
    </div>
  );
};
