import { Button } from '@admin/features/theme/components/Button/Button';
import { cn } from '@admin/utils/cn';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge } from '@admin/features/theme/components/Badge/Badge';
import {
  UserHierarchyNodeDto,
  UserInvitationState,
} from '@admin/features/api/generated';
import { Modal } from '@admin/features/theme/components/Modals/Modal';
import { useHandledMutation } from '@admin/hooks/useHandledMutation';
import { api, queryClient } from '@admin/features/api';
import { FaPen, FaTrash } from 'react-icons/fa6';
import { FaShieldAlt } from 'react-icons/fa';
import { LuGripVertical } from 'react-icons/lu';
import { UserPermissionsModal } from '@admin/features/users/components/UserManagement/components/Modals/UserPermissionsModal';
import { UserNameModal } from '@admin/features/users/components/UserManagement/components/Modals/UserNameModal';
import { usePermissions } from '@admin/features/auth/usePermissions';
import { useQuery } from '@tanstack/react-query';

type UserProps = {
  user: UserHierarchyNodeDto;
  parentId: string | null;
  className?: string;
  level: number;
};

export const User: React.FC<UserProps> = ({
  user,
  parentId,
  className,
  level,
}) => {
  const { t } = useTranslation('users');
  const { user: currentUser, isEditor, isAdmin } = usePermissions();
  const { setNodeRef, attributes, listeners, transform, transition, isOver } =
    useSortable({
      id: user.id,
      data: {
        ...user,
        parentId,
        level,
      },
    });
  const [modalsState, setModalsState] = useState({
    permissions: false,
    name: false,
    deletion: false,
  });

  const { mutate: deleteUser, isPending: isDeletingUser } = useHandledMutation({
    mutationKey: ['userControllerDeleteUser', user.id],
    mutationFn: (id: string) => api.userControllerDeleteUser(id),
    onSuccess: async () => {
      setModalsState((prev) => ({ ...prev, deletion: false }));
      await queryClient.invalidateQueries({
        queryKey: ['all-users'],
      });
    },
  });

  const { data: userPermissions } = useQuery({
    queryKey: ['userPermissions', user.id],
    queryFn: () => api.userControllerFindPermissions(user.id),
  });

  const openModal = (e: React.MouseEvent<HTMLButtonElement>): void => {
    const { modal } = e.currentTarget.dataset;
    if (typeof modal !== 'string') {
      return;
    }

    setModalsState((prev) => ({
      ...prev,
      [modal]: true,
    }));
  };

  const closeModal = (modalName: string): void => {
    setModalsState((prev) => ({
      ...prev,
      [modalName]: false,
    }));
  };
  const handleDeletionModalSave = (): void => {
    deleteUser(user.id);
  };

  return (
    <div
      ref={setNodeRef}
      style={{ transform: CSS.Transform.toString(transform), transition }}
      className={cn(
        isOver && 'border-2 border-blue-600',
        'w-full h-14 rounded-lg bg-white border-2 flex justify-between items-center cursor-default',
        className
      )}
      {...attributes}
    >
      <UserPermissionsModal
        isOpen={modalsState.permissions}
        onClose={() => {
          closeModal('permissions');
        }}
        user={user}
      />
      <UserNameModal
        isOpen={modalsState.name}
        onClose={() => {
          closeModal('name');
        }}
        user={user}
      />

      <Modal
        isOpen={modalsState.deletion}
        onClose={() => {
          closeModal('deletion');
        }}
        onConfirm={handleDeletionModalSave}
        title={t('list.modal.deletion.user.title', { user: user.fullName })}
        loading={isDeletingUser}
      >
        <h1>{t('list.modal.deletion.user.text')}</h1>
      </Modal>
      <div className='flex space-x-4 items-center h-full'>
        <div
          className='flex justify-center w-10 h-full rounded-l-lg bg-gray-100 border-r-2 text-gray-400 cursor-grabbing'
          {...listeners}
        >
          <LuGripVertical className='h-full text-gray-400 text-sm' />
        </div>
        <div className='flex items-center gap-2'>
          <h1 className='font-medium'>{user.fullName}</h1>
          <span className='text-[0.6rem] text-gray-500'>
            {userPermissions?.data.userLevelAccessRole.split('_')[1]}
          </span>
        </div>
      </div>
      <div className='flex gap-2 items-center justify-between px-4'>
        <Badge variant={'outline'}>
          {user.state === UserInvitationState.ACTIVE
            ? t('list.status.active')
            : t('list.status.pending')}
        </Badge>
        {isAdmin && (
          <Button
            data-modal='permissions'
            variant='link'
            size='sm'
            onClick={openModal}
          >
            <FaShieldAlt />
          </Button>
        )}
        {currentUser?.id === user.id ? (
          <Button
            data-modal='name'
            variant='link'
            size='sm'
            onClick={openModal}
          >
            <FaPen />
          </Button>
        ) : !isEditor ? (
          <Button
            data-modal='deletion'
            onClick={openModal}
            variant='link'
            size='sm'
            className='text-red-700'
            loading={isDeletingUser}
          >
            <FaTrash />
          </Button>
        ) : (
          <div className='w-[38px]' />
        )}
      </div>
    </div>
  );
};
