import transliterate from '@sindresorhus/transliterate';

export const convertStringToSlug = (str: string): string => {
  return transliterate(str)
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/\s+/g, '-')
    .replace(/--+/g, '-')
    .replace(/^-+|-+$/g, '');
};
