export class Storage<T> {
  private readonly storage: typeof localStorage;

  constructor() {
    // can be sessionStorage
    this.storage = localStorage;
  }

  public read<K extends keyof T>(key: K, defaultValue?: T[K]): T[K] {
    return (
      JSON.parse(
        this.storage.getItem(key.toString()) ?? JSON.stringify(null)
      ) || defaultValue
    );
  }

  public write<K extends keyof T>(key: K, value: T[K]): void {
    this.storage.setItem(key.toString(), JSON.stringify(value));
  }

  public remove<K extends keyof T>(key: K): void {
    this.storage.removeItem(key.toString());
  }

  public clear(): void {
    this.storage.clear();
  }
}
