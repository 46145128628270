import {
  Button,
  ButtonProps,
} from '@admin/features/theme/components/Button/Button';
import { FC, useState } from 'react';
import { Modal } from '@admin/features/theme/components/Modals/Modal';
import { useTranslation } from 'react-i18next';

export type RemoveButtonProps = ButtonProps & {
  onConfirm?(): void;
  buttonLabel?: string | React.ReactNode;
};

export const RemoveButton: FC<RemoveButtonProps> = ({
  children,
  onConfirm,
  buttonLabel,
  onClick,
  ...props
}) => {
  const { t } = useTranslation('common');
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => {
    setIsOpen(false);
  };
  const handleConfirm = () => {
    setIsOpen(false);
    onConfirm?.();
  };
  const handleOpen: RemoveButtonProps['onClick'] = (e) => {
    setIsOpen(true);
    onClick?.(e);
  };
  return (
    <>
      <Modal
        isOpen={isOpen}
        title={t('dialog.content.removeConfirmation.title')}
        onClose={handleClose}
        onConfirm={handleConfirm}
      >
        <div className='whitespace-pre-line'>
          {children ?? t('dialog.content.removeConfirmation.description')}
        </div>
      </Modal>
      <Button {...props} onClick={handleOpen}>
        {buttonLabel ?? t('actions.remove')}
      </Button>
    </>
  );
};
