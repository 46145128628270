import { useEffect, useState } from 'react';
import { Button } from '@admin/features/theme/components/Button/Button';
import { Listbox, ListboxButton } from '@headlessui/react';
import { DropdownContainer } from '@admin/features/theme/components/DropdownContainer/DropdownContainer';
import { DropdownOption } from '@admin/features/theme/components/DropdownOption/DropdownOption';
import { StaticCheckbox } from '@admin/features/theme/components/StaticCheckbox/StaticCheckbox';
import { useFormContext } from 'react-hook-form';
import { Language } from '@admin/features/api/generated';
import { FaExclamationCircle } from 'react-icons/fa';

export type DropdownOption<
  T extends string = string,
  I extends string = string,
> = {
  id: I;
  name: T;
};

type SelectLanguageProps = {
  title: string;
  defaultSelectedOptions?: DropdownOption<Language, Language>[];
  onChange?(selectedItems: DropdownOption<Language, Language>[]): void;
  onLanguageChange?(languageId: Language): void;
  selectedLanguageId?: string;
  getTranslations(language: Language, defaultValues?: object): object;
  useFallbackTranslation?: boolean;
  errorLanguages?: Language[];
};

const TRANSLATIONS_NAME = 'translations';

export const DEFAULT_LANGUAGE_OPTION: DropdownOption<Language, Language> = {
  id: Language.Cs,
  name: Language.Cs,
};

export const LANGUAGE_OPTIONS: DropdownOption<Language, Language>[] = [
  DEFAULT_LANGUAGE_OPTION,
  {
    id: Language.En,
    name: Language.En,
  },
];

export const SelectLanguage: React.FC<SelectLanguageProps> = ({
  title,
  defaultSelectedOptions,
  selectedLanguageId,
  onChange,
  getTranslations,
  onLanguageChange,
  useFallbackTranslation = true,
  errorLanguages = [],
}) => {
  const { setValue, getValues } = useFormContext();

  const [selectedOptions, setSelectedOptions] = useState<
    DropdownOption<Language, Language>[]
  >([]);

  const selectLanguageHandler = (
    options: DropdownOption<Language, Language>[]
  ): void => {
    setSelectedOptions(options);
    onChange?.(options);

    const translations: { language: Language }[] = getValues(TRANSLATIONS_NAME);

    // Update translations
    setValue(
      TRANSLATIONS_NAME,
      options.map((option) => {
        const translation = translations.find(
          ({ language }) => option.name === language
        );

        if (translation) {
          return { ...translation };
        }

        const fallbackTranslation =
          translations.find(({ language }) => language === Language.Cs) ??
          translations[0];

        if (useFallbackTranslation) {
          return getTranslations(option.name, fallbackTranslation);
        }
        return getTranslations(option.name);
      })
    );
  };

  const selectLanguageTabHandler = (
    option: DropdownOption<Language, Language>
  ) => {
    return (): void => {
      onLanguageChange?.(option.id);
    };
  };

  const selectedOptionIds = selectedOptions.map(({ id }) => id);

  useEffect(() => {
    setSelectedOptions(defaultSelectedOptions ?? []);
  }, [defaultSelectedOptions]);

  return (
    <div className='flex flex-wrap gap-4 items-center'>
      <div className='relative w-max'>
        <Listbox
          multiple
          value={selectedOptions}
          onChange={selectLanguageHandler}
        >
          <ListboxButton as={Button}>{title}</ListboxButton>
          <DropdownContainer>
            {LANGUAGE_OPTIONS.map((option) => {
              const checked = selectedOptionIds.includes(option.id);
              return (
                <DropdownOption key={option.id} value={option}>
                  <StaticCheckbox
                    disabled={checked && selectedOptions.length <= 1}
                    checked={checked}
                    onChange={() => {
                      /* */
                    }}
                  />
                  {option.name}
                </DropdownOption>
              );
            })}
          </DropdownContainer>
        </Listbox>
      </div>
      {selectedOptions.map((language) => (
        <Button
          size='sm'
          key={language.id}
          variant={language.id === selectedLanguageId ? 'default' : 'ghost'}
          onClick={selectLanguageTabHandler(language)}
        >
          {errorLanguages.includes(language.id) && (
            <span className='text-red-500'>
              <FaExclamationCircle />
            </span>
          )}
          {language.name}
        </Button>
      ))}
    </div>
  );
};
