import { OpenModalFunc } from '@admin/features/layout/modals/types';
import { modalActions } from './modalSlice';
import { useDispatch, useSelector } from '@admin/store/store';
import { useCallback } from 'react';

type ModalState = {
  Modal: React.FC;
  openModal: OpenModalFunc;
  closeModal: VoidFunction;
  openPreviousModal: VoidFunction;
  store: React.ReactNode[];
};

export const useModal = (): ModalState => {
  const dispatch = useDispatch();
  const store = useSelector((state) => state.modal.store);
  const Component = store[store.length - 1] ?? null;

  const handleOpenModal: OpenModalFunc = (component) => {
    dispatch(modalActions.openModal(component));
  };

  const handleCloseModal = (): void => {
    dispatch(modalActions.closeModal());
  };

  const handleOpenPreviousModal = (): void => {
    dispatch(modalActions.openPreviousModal());
  };

  const Modal = useCallback((): React.ReactNode => {
    return Component;
  }, [Component]);

  return {
    store,
    openModal: handleOpenModal,
    closeModal: handleCloseModal,
    openPreviousModal: handleOpenPreviousModal,
    Modal,
  };
};
