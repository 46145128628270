import { Navbar } from '@admin/features/layout/components/Navigation/Navbar';
import { Sidebar } from '@admin/features/layout/components/Navigation/Sidebar';
import { FC, PropsWithChildren } from 'react';

export const Layout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className='flex flex-col min-h-screen h-screen relative'>
      <Navbar />
      <div className='flex h-full w-full'>
        <Sidebar />
        {children}
      </div>
    </div>
  );
};
