// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noop = (): void => {};
export const nullop = (): null => null;

export const toggleValue = (arr: string[], value: string): string[] => {
  return arr.includes(value)
    ? arr.filter((item) => item !== value)
    : [...arr, value];
};

export const addIfMissing = (arr: string[], value: string): string[] => {
  return arr.includes(value) ? arr : [...arr, value];
};
