import { Button } from '@admin/features/theme/components/Button/Button';
import { cn } from '@admin/utils/cn';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaTrash } from 'react-icons/fa6';
import { UserResponseDto } from '@admin/features/api/generated';
import { Modal } from '@admin/features/theme/components/Modals/Modal';
import { useHandledMutation } from '@admin/hooks/useHandledMutation';
import { api, queryClient } from '@admin/features/api';
import { FaShieldAlt } from 'react-icons/fa';
import { UserPermissionsModal } from '@admin/features/users/components/UserManagement/components/Modals/UserPermissionsModal';
import { usePermissions } from '@admin/features/auth/usePermissions';
import { Badge } from '@admin/features/theme/components/Badge/Badge';

type PendingUserProps = {
  user: UserResponseDto;
};

export const PendingUser: React.FC<PendingUserProps> = ({ user }) => {
  const { t } = useTranslation('users');
  const { isEditor, isAdmin } = usePermissions();
  const [modalsState, setModalsState] = useState({
    permissions: false,
    deletion: false,
  });

  const { mutate: deleteUser, isPending: isDeletingUser } = useHandledMutation({
    mutationKey: ['userControllerDeleteUser', user.id],
    mutationFn: (id: string) => api.userControllerDeleteUser(id),
    onSuccess: async () => {
      setModalsState((prev) => ({ ...prev, deletion: false }));
      await queryClient.invalidateQueries({
        queryKey: ['all-users'],
      });
    },
  });

  const openModal = (e: React.MouseEvent<HTMLButtonElement>): void => {
    const { modal } = e.currentTarget.dataset;
    if (typeof modal !== 'string') {
      return;
    }

    setModalsState((prev) => ({
      ...prev,
      [modal]: true,
    }));
  };

  const closeModal = (modalName: string): void => {
    setModalsState((prev) => ({
      ...prev,
      [modalName]: false,
    }));
  };
  const handleDeletionModalSave = (): void => {
    deleteUser(user.id);
  };

  return (
    <div
      className={cn(
        'w-full h-12 rounded-lg border-b border-orange-700 bg-orange-100 flex justify-between items-center p-4'
      )}
    >
      <UserPermissionsModal
        isOpen={modalsState.permissions}
        onClose={() => {
          closeModal('permissions');
        }}
        user={user}
      />
      <Modal
        isOpen={modalsState.deletion}
        onClose={() => {
          closeModal('deletion');
        }}
        onConfirm={handleDeletionModalSave}
        title={t('list.modal.deletion.user.title', { user: user.fullName })}
        loading={isDeletingUser}
      >
        <h1>{t('list.modal.deletion.user.text')}</h1>
      </Modal>
      <div className='flex space-x-4 items-center'>
        <h1 className='font-medium'>{user.fullName}</h1>
      </div>
      <div className='flex gap-2 items-center justify-between'>
        <Badge className='bg-transparent border border-black text-black hover:bg-transparent select-none'>
          {t('list.status.pending')}
        </Badge>
        {isAdmin && (
          <Button
            data-modal='permissions'
            variant='link'
            size='sm'
            onClick={openModal}
          >
            <FaShieldAlt />
          </Button>
        )}
        {!isEditor ? (
          <Button
            data-modal='deletion'
            onClick={openModal}
            variant='link'
            size='sm'
            className='text-red-700'
            loading={isDeletingUser}
          >
            <FaTrash />
          </Button>
        ) : (
          <div className='w-[38px]' />
        )}
      </div>
    </div>
  );
};
