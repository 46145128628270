import { ListboxOptions } from '@headlessui/react';
import React, { PropsWithChildren } from 'react';

export const DropdownContainer: React.FC<PropsWithChildren> = ({
  children,
}) => {
  return (
    <ListboxOptions
      portal
      anchor='bottom'
      className='h-48 w-[var(--button-width)] overflow-y-auto text-sm text-gray-700 dark:text-gray-200 h-fit z-10 bg-white rounded-lg shadow mt-1 dark:bg-gray-700 absolute'
    >
      {children}
    </ListboxOptions>
  );
};
