import { Modal } from '@admin/features/theme/components/Modals/Modal';
import React from 'react';
import { Tag } from '@admin/features/blogs/components/Tag/Tag';
import { SearchField } from '@admin/features/theme/components/SearchField/SearchField';
import { useGetAutofillTagsData } from './useGetAutofillTagsData';
import { Button } from '@admin/features/theme/components/Button/Button';
import { TagResponseDto } from '@admin/features/api/generated';
import { TagsGalleryModal } from '../TagsGalleryModal/TagsGalleryModal';
import { useModal } from '@admin/features/layout/modals/useModal';
import { useBlogsTranslation } from '@admin/features/blogs/locales/useBlogsTranslation';
import { useFormContext } from 'react-hook-form';
import { toggleValue } from '@admin/utils';
import { useSelector } from '@admin/store/store';

export const TAG_IDS_NAME = 'tagIds';

const TagsSearchModal: React.FC = () => {
  const { t } = useBlogsTranslation();
  const { openModal, closeModal } = useModal();
  const tags = useSelector((state) => state.editor.tags);

  const { watch, setValue } = useFormContext();

  const selectedTagIds: string[] = watch(TAG_IDS_NAME);

  const autofillTagsData = useGetAutofillTagsData(tags);

  const openTagsGalleryHandler = (): void => {
    openModal(<TagsGalleryModal />);
  };

  const removeTagHandler = (tag: TagResponseDto): void => {
    setValue(TAG_IDS_NAME, toggleValue(selectedTagIds, tag.id));
  };

  const selectedTags = tags.filter(({ id }) => selectedTagIds.includes(id));

  return (
    <Modal
      isOpen
      title={t('editor.modals.select-tags.title')}
      onClose={closeModal}
      onConfirm={closeModal}
      closeOnOutsideClick
    >
      <Button onClick={openTagsGalleryHandler}>
        {t('editor.modals.select-tags.buttons.tags-gallery')}
      </Button>
      <SearchField
        placeholder={t('editor.modals.select-tags.placeholder')}
        autofillData={autofillTagsData}
        name={TAG_IDS_NAME}
      />
      <Tag.Group>
        {tags
          .filter((tag) => selectedTags.find(({ id }) => id === tag.id))
          .map((tag) => (
            <Tag key={tag.id} {...tag} onRemove={removeTagHandler} />
          ))}
      </Tag.Group>
    </Modal>
  );
};

export default TagsSearchModal;
