import React, { ComponentPropsWithoutRef } from 'react';

export const StaticCheckbox: React.FC<ComponentPropsWithoutRef<'input'>> = (
  props
) => {
  return (
    <input
      {...props}
      type='checkbox'
      className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500'
    />
  );
};
