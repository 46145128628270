import {
  Alert,
  AlertTitle,
} from '@admin/features/theme/components/Alert/Alert';
import { useTranslation } from 'react-i18next';

export type EmptyListAlertProps = {
  title?: string;
};
export const EmptyListAlert = ({ title: titleProp }: EmptyListAlertProps) => {
  const { t } = useTranslation('layout');
  const title = titleProp ?? t('list.emptyAlert');
  return (
    <Alert variant='info'>
      <AlertTitle>{title}</AlertTitle>
    </Alert>
  );
};
