import { CSSProperties, FC, useLayoutEffect, useRef, useState } from 'react';
import { Snackbar } from '@admin/features/theme/components/Snackbar/Snackbar';
import { useSelector } from '@admin/store/store';
import { Transition, TransitionStatus } from 'react-transition-group';
import { Notification } from '@admin/features/layout/types/Notification';

const inOutDuration = 400;

const defaultStyle = {
  transition: `opacity ${inOutDuration}ms ease-in-out`,
  opacity: 0,
};

const transitionStyles: Record<TransitionStatus, CSSProperties> = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
  unmounted: {},
};

const SnackbarQueueItem = ({
  ...notification
}: Notification & { index: number }): React.ReactNode => {
  const nodeRef = useRef<HTMLDivElement>(null);
  // initially hidden
  const [entered, setEntered] = useState(false);
  useLayoutEffect(() => {
    // do this immediately
    setTimeout(() => {
      setEntered(true);
      setTimeout(
        () => {
          setEntered(false);
        },
        notification.timeout - inOutDuration * 2
      );
      // workaround for immediately appearing snackbars
    }, 10);
  }, [notification.timeout]);
  return (
    <Transition nodeRef={nodeRef} in={entered} timeout={inOutDuration}>
      {(state) => (
        <div
          ref={nodeRef}
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
          }}
        >
          <Snackbar {...notification} />
        </div>
      )}
    </Transition>
  );
};

export type SnackbarQueueProps = Record<never, unknown>;

export const SnackbarQueue: FC<SnackbarQueueProps> = () => {
  const queue = useSelector((state) => state.layout.notifications);
  return (
    <div className='z-[100] absolute right-4 bottom-4 w-full max-w-sm flex flex-col-reverse gap-y-2'>
      {queue.map((notification, index) => (
        <SnackbarQueueItem
          {...notification}
          key={notification.id}
          index={index}
        />
      ))}
    </div>
  );
};
