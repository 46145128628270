import { setLocale } from 'yup';

import './locales';

// add more if necessary
setLocale({
  mixed: {
    required: 'form.validation.errors.required',
  },
  string: {
    email: 'form.validation.errors.email',
  },
});
