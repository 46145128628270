import React, { useState } from 'react';
import { api } from '@admin/features/api';
import { useQuery } from '@tanstack/react-query';
import { useBlogsTranslation } from '../../locales/useBlogsTranslation';
import { usePermissions } from '@admin/features/auth/usePermissions';
import { PaginatedBlogList } from '@admin/features/blogs/components/PaginatedBlogList/PaginatedBlogList';

type SubmittedBlogListProps = {
  limit: number;
};

export const SubmittedBlogList: React.FC<SubmittedBlogListProps> = ({
  limit,
}) => {
  const { t } = useBlogsTranslation();
  const [page, setPage] = useState(0);

  const { isFetching, data } = useQuery({
    queryFn: () => api.blogControllerGetSubmittedBlogs({ page, limit }),
    queryKey: ['blogControllerGetSubmittedBlogs', page],
    staleTime: 0,
  });

  const blogs = data?.data.data ?? [];
  const totalItems = data?.data.totalCount;

  const { isEditor } = usePermissions();
  if (blogs.length === 0) {
    return null;
  }

  return (
    <PaginatedBlogList
      isFetching={isFetching}
      totalItems={totalItems ?? blogs.length}
      blogs={blogs}
      title={t('list.group.submitted')}
      page={page}
      limit={limit}
      onPageChange={setPage}
      rootClassName='bg-orange-100 border-l-4 border-orange-500 p-2'
      allowToApprove={!isEditor}
    />
  );
};
