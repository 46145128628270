class EnvironmentVariables {
  constructor(
    private readonly NODE_ENV?: 'production' | 'development',
    private readonly VITE_API_URL?: string,
    private readonly VITE_LANDING_PAGE_URL?: string
  ) {}

  get apiUrl(): string {
    return new URL(this.VITE_API_URL ?? '').toString();
  }

  get landingPageUrl(): string {
    return new URL(this.VITE_LANDING_PAGE_URL ?? '').toString();
  }

  get nodeEnv(): EnvironmentVariables['NODE_ENV'] {
    return this.NODE_ENV;
  }
}

const env = new EnvironmentVariables(
  import.meta.env.NODE_ENV,
  import.meta.env.VITE_API_URL,
  import.meta.env.VITE_LANDING_PAGE_URL
);

export default env;
