import { DefaultValues, useForm as useFormBase } from 'react-hook-form';
import {
  FieldValues,
  UseFormProps as BaseUseFormProps,
  UseFormReturn,
} from 'react-hook-form/dist/types';
import { ObjectSchema, Schema } from 'yup';

import { useYupValidationResolver } from '@admin/hooks/useYupValidationResolver';

export type UseFormProps<
  TFieldValues extends FieldValues = FieldValues,
  TContext = unknown,
> = Omit<BaseUseFormProps<TFieldValues, TContext>, 'resolver'> & {
  schema: ObjectSchema<TFieldValues>;
};

export const useForm = <
  TFieldValues extends FieldValues = FieldValues,
  TContext = unknown,
  TTransformedValues extends FieldValues | undefined = undefined,
>({
  schema,
  defaultValues,
  ...props
}: UseFormProps<TFieldValues, TContext>): UseFormReturn<
  TFieldValues,
  TContext,
  TTransformedValues
> => {
  // for some reason, there is MakeKeysOptional enforced
  const resolver = useYupValidationResolver<TFieldValues>(
    schema as Schema<TFieldValues>
  );
  return useFormBase({
    ...props,
    defaultValues:
      defaultValues ?? (schema.cast({}) as DefaultValues<TFieldValues>),
    resolver,
  });
};
