import { BlogGroupWithBlogsDto } from '@admin/features/api/generated';
import { useQuery } from '@tanstack/react-query';
import { api } from '@admin/features/api';
import { AxiosResponse } from 'axios';
import { useMemo, useState } from 'react';
import { BlogGroupListResponse } from '@admin/features/blogs/components/BlogGroupList/BlogGroupListResponse';
import { useBlogsTranslation } from '../../locales/useBlogsTranslation';
import { PaginatedBlogList } from '@admin/features/blogs/components/PaginatedBlogList/PaginatedBlogList';

export type BlogGroupListProps = {
  blogGroup: BlogGroupWithBlogsDto;
  limit: number;
};

export const BlogGroupList: React.FC<BlogGroupListProps> = ({
  blogGroup,
  limit,
}) => {
  const { t } = useBlogsTranslation();
  const [page, setPage] = useState(0);
  const { data, isFetching } = useQuery({
    queryFn: () =>
      api.blogControllerGetBlogsByGroup(blogGroup.id, { page, limit }),
    queryKey: ['blogControllerGetBlogsByGroup', blogGroup.id, page],
    initialData: () => {
      // we only have the first page
      if (page > 0) {
        return undefined;
      }
      return {
        data: {
          totalCount: blogGroup.totalBlogCount,
          data: blogGroup.blogs,
        },
      } as AxiosResponse<BlogGroupListResponse>;
    },
  });
  const totalItems = data?.data.totalCount ?? blogGroup.totalBlogCount;
  const blogs = useMemo(
    () =>
      data?.data.data?.map((blog) => ({
        ...blog,
        blogGroup,
      })) ?? [],
    [blogGroup, data?.data.data]
  );
  return (
    <PaginatedBlogList
      isFetching={isFetching || !data?.data.data}
      totalItems={totalItems}
      title={t('list.group.title', {
        group: blogGroup.name,
      })}
      page={page}
      limit={limit}
      onPageChange={setPage}
      blogs={blogs}
      disableGroupColumn
    />
  );
};
