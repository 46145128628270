import { UserHierarchyNodeDto } from '@admin/features/api/generated';
import { useDroppable } from '@dnd-kit/core';
import clsx from 'clsx';
import React from 'react';

type CatcherProps = {
  user: UserHierarchyNodeDto;
  parentId: string | null;
  className?: string;
  direction: 'upper' | 'lower';
  moveTo: number;
  users: UserHierarchyNodeDto[];
};

export const Catcher: React.FC<CatcherProps> = ({
  user,
  parentId,
  direction,
  moveTo,
  users,
}) => {
  const { setNodeRef, isOver } = useDroppable({
    id: `catcher_${direction}_${user.id}`,
    data: {
      ...user,
      parentId,
      moveTo,
      users,
    },
  });

  return (
    <div
      ref={setNodeRef}
      className={clsx(
        'w-full h-3 transition-all rounded-lg duration-150',
        isOver && 'border-2 min-h-12 my-2 bg-transparent border-blue-600'
      )}
    ></div>
  );
};
