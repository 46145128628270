import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type ModalState = {
  store: React.ReactNode[];
};

const initialState: ModalState = {
  store: [],
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: (state, action: PayloadAction<React.ReactNode>) => {
      state.store.push(action.payload);
    },
    closeModal: (state) => {
      state.store = [];
    },
    openPreviousModal: (state) => {
      state.store.pop();
    },
  },
});

export const modalActions = modalSlice.actions;
export const modalReducer = modalSlice.reducer;
