import { useTranslation, UseTranslationResponse } from 'react-i18next';

const BLOGS_TRANSLATION_NAMESPACE = 'blogs';

export const useBlogsTranslation = (): UseTranslationResponse<
  'blogs',
  undefined
> => {
  return useTranslation(BLOGS_TRANSLATION_NAMESPACE);
};
