import { FC, useEffect } from 'react';
import { PageTemplate } from '@admin/features/layout/components/PageTemplate/PageTemplate';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { api } from '@admin/features/api';
import { Loader } from '@admin/features/theme/components/Loader/Loader';
import { EmptyListAlert } from '@admin/features/layout/components/PageTemplate/EmptyListAlert';
import { BlogGroupList } from '@admin/features/blogs/components/BlogGroupList/BlogGroupList';
import { Button } from '@admin/features/theme/components/Button/Button';
import { Link } from '@tanstack/react-router';
import { SubmittedBlogList } from '../SubmittedBlogList/SubmittedBlogList';
import { useBlogsTranslation } from '../../locales/useBlogsTranslation';
import { ApprovedBlogList } from '@admin/features/blogs/components/ApprovedBlogList/ApprovedBlogList';

export type BlogListPageProps = Record<never, unknown>;

const LIMIT = 5;

export const BlogListPage: FC<BlogListPageProps> = () => {
  const { t } = useBlogsTranslation();
  const { isFetching, data } = useQuery({
    queryFn: () =>
      api.blogControllerGetBlogGroupsWithBlogs({ blogLimit: LIMIT }),
    queryKey: ['blogControllerGetBlogGroupsWithBlogs'],
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: 0,
  });

  const queryClient = useQueryClient();

  useEffect(() => {
    return (): void => {
      void queryClient.invalidateQueries({
        queryKey: ['blogControllerGetBlogGroupsWithBlogs'],
      });
    };
  }, [queryClient]);

  const groups = data?.data ?? [];

  return (
    <PageTemplate title={t('list.title')}>
      <Button as={Link} to='create-blog'>
        {t('list.links.editor')}
      </Button>
      {isFetching ? (
        <Loader />
      ) : groups.length === 0 ? (
        <EmptyListAlert />
      ) : (
        <div className='flex flex-col gap-y-4'>
          <SubmittedBlogList limit={LIMIT} />
          <ApprovedBlogList limit={LIMIT} />
          {groups.map((bg) => (
            <BlogGroupList key={bg.id} blogGroup={bg} limit={LIMIT} />
          ))}
        </div>
      )}
    </PageTemplate>
  );
};
