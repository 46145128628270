import { PageTemplate } from '@admin/features/layout/components/PageTemplate/PageTemplate';
import React, { useMemo, useState } from 'react';
import {
  closestCorners,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { useTranslation } from 'react-i18next';
import { Button } from '@admin/features/theme/components/Button/Button';
import { FaPlus } from 'react-icons/fa6';
import { Link } from '@tanstack/react-router';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { api } from '@admin/features/api';
import {
  UserHierarchyNodeDto,
  UserSortDto,
} from '@admin/features/api/generated';
import { Loader } from '@admin/features/theme/components/Loader/Loader';
import { EmptyListAlert } from '@admin/features/layout/components/PageTemplate/EmptyListAlert';
import { useHandledMutation } from '@admin/hooks/useHandledMutation';
import { usePermissions } from '@admin/features/auth/usePermissions';
import { PendingUsers } from './components/PendingUsers/PendingUsers';
import { getIndexByUserID, isPendingUser } from './utils/filter';
import { arrayMove } from '@dnd-kit/sortable';
import { UserList } from './components/Items/Users/UserList';
import { User } from './components/Items/Users/User';
import { flatten } from '@admin/utils/flatten';

export const UserManagement: React.FC = () => {
  const [activeItem, setActiveItem] = useState<UserHierarchyNodeDto | null>(
    null
  );
  const [isProcessing, setIsProcessing] = useState(false);
  const { isEditor } = usePermissions();
  const { t } = useTranslation('users');
  const queryClient = useQueryClient();
  const pointerSensor = useSensor(PointerSensor, {
    activationConstraint: {
      distance: 4,
    },
  });
  const sensors = useSensors(pointerSensor);

  const { data, isError, isLoading, isSuccess } = useQuery({
    queryKey: ['all-users'],
    queryFn: () => api.userControllerGetUserHierarchy(),
  });
  const users = useMemo(() => data?.data ?? [], [data]);

  const { mutate: mutateSupervisor } = useHandledMutation({
    mutationFn: (data: { userId: string; supervisorUserId: string }) =>
      api.userControllerSetSupervisor(data.userId, {
        supervisorUserId: data.supervisorUserId,
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['all-users'] });
    },
  });

  const { mutate: mutateSortUsers } = useHandledMutation({
    mutationFn: (data: UserSortDto) => api.userControllerSortUsers(data),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['all-users'] });
      setIsProcessing(false);
    },
  });

  const onDragStart = (e: DragStartEvent): void => {
    setActiveItem(e.active.data.current as UserHierarchyNodeDto);
  };

  const onDragEnd = (e: DragEndEvent): void => {
    setActiveItem(null);
    const { active, over } = e;
    if (
      !over ||
      active.id === over.id ||
      over.data.current?.parentId === active.id
    ) {
      return;
    }

    const isCatcher = over.id.toString().startsWith('catcher');
    const supervisorUserId = isCatcher ? over.data.current?.parentId : over.id;

    let currentUsers = [...users];
    const flatUsers = flatten(users, (u) => u.subordinates);
    const activeUser = flatUsers.find((u) => u.id === active.id)!;
    const formerSupervisor = flatUsers.find((u) =>
      u.subordinates.find((s) => s.id === active.id)
    );
    const newSupervisor = flatUsers.find((u) => u.id === supervisorUserId);
    if (formerSupervisor?.id !== newSupervisor?.id) {
      if (formerSupervisor) {
        formerSupervisor.subordinates = formerSupervisor.subordinates.filter(
          (s) => s.id !== active.id
        );
      }
      if (newSupervisor) {
        newSupervisor.subordinates = [
          ...newSupervisor.subordinates,
          activeUser,
        ];
      } else if (formerSupervisor) {
        currentUsers = [...currentUsers, activeUser];
      }
    }
    mutateSupervisor({
      userId: active.id.toString(),
      supervisorUserId,
    });

    if (isCatcher) {
      const usersToSort = newSupervisor
        ? newSupervisor.subordinates
        : currentUsers;
      const indexToMove = over.data.current?.moveTo;
      const currentIndex = getIndexByUserID(usersToSort, active.id.toString());
      const sortedUsers = arrayMove(usersToSort, currentIndex, indexToMove);
      const sortedIds = sortedUsers.map((u) => u.id);
      const body: UserSortDto = {
        users: sortedIds,
      };

      mutateSortUsers(body);
      setIsProcessing(true);
    }
  };

  return (
    <>
      {isProcessing && (
        <div className='absolute inset-0 bg-black/20 h-screen w-screen flex justify-center items-center overflow-hidden'>
          <Loader />
        </div>
      )}
      <PageTemplate title={t('list.title')}>
        {!isEditor && (
          <Button
            as={Link}
            className='bg-green-500 hover:bg-green-600 space-x-2'
            to='/users/invite-user'
          >
            <FaPlus />
            <span>{t('list.invite.btn')}</span>
          </Button>
        )}
        <PendingUsers />
        <h1 className='font-semibold text-xl'>{t('list.users.title')}</h1>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCorners}
          onDragStart={onDragStart}
          onDragEnd={onDragEnd}
        >
          {isLoading && <Loader />}
          {isError && <h1 className='text-red-600'>{t('list.error.msg')}</h1>}
          {isSuccess &&
            (data.data.length !== 0 ? (
              <div>
                <UserList
                  id='users'
                  className='w-full h-full flex flex-col items-center'
                  users={users}
                />

                {activeItem && (
                  <DragOverlay>
                    <User
                      parentId={null}
                      level={0}
                      user={activeItem}
                      className='h-10'
                    />
                  </DragOverlay>
                )}
              </div>
            ) : (
              <EmptyListAlert />
            ))}
        </DndContext>
      </PageTemplate>
    </>
  );
};
